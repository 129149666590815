.chromatogram-graph {
    position : relative;
    width    : 100%;
    height   : 100%;
}

.chromatogram-graph__trace {
    fill         : none;
    stroke       : rgb(117, 117, 117);
    stroke-width : 1px;
    opacity      : 0.5;
}

.chromatogram-graph__axes {
    width    : 100%;
    height   : 100%;
    position : absolute;
}

.chromatogram-graph__drawing-layer {
    position  : absolute;
}

svg.scan-selector-svg {
    width     : calc(100% - 38px);
    height    : 185px;
    transform : matrix(1, 0, 0, -1, 20, 15)
}

svg.baseline-controls-svg {
    width     : calc(100% - 38px);
    height    : 185px;
    transform : matrix(1, 0, 0, -1, 20, 15);
    overflow  : visible;
    display   : none;
}

svg.peak-anchors-controls-svg {
    width          : calc(100% - 38px);
    height         : 185px;
    transform      : matrix(1, 0, 0, -1, 20, 15);
    overflow       : visible;
    pointer-events : none;
}

.chromatogram-graph__alignment {
    height    : 25px;
    cursor    : grab;
}

/*LEGACY*/
.total-signal-chromatogram-baseline-area {
    opacity : 0.25;
}

.extracted-signal-chromatogram {
    fill         : none;
    stroke-width : 1px;
}

.chromatogram-graph svg {
    position : absolute;
}

.chromatogram-graph-svg-text {
    /*
    -1 turns they y coordinates upside down. So whatever was at the top is now at the bottom. This makes the
    actual text upside down. This is needed because the text's parent is also upside down, so we do yet another
    turn to fix the text.
    */
    transform : matrix(1, 0, 0, -1, 0, 0)
}

.baseline-anchor[activated="true"] {
    fill-opacity : 0;
    stroke       : #2b2b2b;
}

.peak-baseline-anchors-link {
    pointer-events : none;
    stroke         : #2b2b2b;
    stroke-width   : 1px;
}

g.peak-controls rect.selection {
    stroke : none;
}