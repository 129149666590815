.action-panel {
    --action-panel-background-color: rgb(51, 51, 51);
    --action-panel-background-color-hover: rgb(84, 84, 84);
    --action-panel-text-label-color: rgb(255, 255, 255);
    --action-panel-cancel-icon-color: rgb(196, 196, 196);
    --action-panel-button-disabled-color: rgb(136, 136, 136);
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 4rem;
    min-width: 30rem;
    background: var(--action-panel-background-color);
    border-radius: var(--border-radius);
    color: var(--action-panel-text-label-color)
}

.action-panel__body {
    padding: 1rem;
    height: 4rem;
}

.action-panel__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
}

.action-panel__selected-block {
    display: flex;
    align-items: center;
}

.action-panel__selected-count {
    padding-right: 0.375rem;
}

.action-panel__cancel-selection-icon {
    padding-left: 0.5rem;
    font-size: 1.25rem;
    color: var(--action-panel-cancel-icon-color);
    cursor: pointer;
}

.action-panel__cancel-selection-icon:hover {
    color: var(--action-panel-text-label-color);
}

.action-panel__action-button {
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    background: var(--action-panel-background-color);
    cursor: default;
    color: var(--action-panel-text-label-color);
    margin-left: 1rem;
}

.action-panel__action-button[disabled]{
    color: var(--action-panel-button-disabled-color);
}

.action-panel__action-button:hover:not([disabled]) {
    cursor: pointer;
}

.action-panel__action-icon {
    font-size: 1.25rem;
    color: inherit;
}

.action-panel__action-text {
    color: inherit;
}