.injection-properties-dialog {
    width: 50rem;
    max-height: 90vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.injection-properties-dialog__content-block {
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.injection-properties-dialog__content {
    max-height: calc(100% - 2.5rem);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.injection-properties-dialog__property-inputs{
    overflow-y: auto;
    padding-right: 1rem;
}

.injection-properties-dialog__title-row {
    height: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.injection-properties-dialog__add-property-row {
    height: 1.5rem;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--theme-text-primary-on-light);
    opacity: 0.8;
    width: max-content;
    padding-right: 0.5rem;
}

.injection-properties-dialog__add-property-row:hover {
   opacity: 1;
}

.injection-properties-dialog__add-property-row[disabled]{
    opacity: 0.5;
    cursor: default;
}

.injection-properties-dialog__title{
    font-size: 1.5rem;
    font-weight: bold;
}

.injection-properties-dialog__prompt {
    margin: 2rem auto;
    text-align: center;
}

.injection-properties-dialog__icon {
    padding-left: 0.5rem;
    font-size: 1.25rem;
    color: var(--mdc-theme-text-icon-on-background);
    cursor: pointer;
}

.injection-properties-dialog__icon:hover {
    color: var(--mdc-theme-text-primary-on-background);
}

.injection-properties-dialog__add-icon {
    height: 1.25rem;
    width: 1.25rem;
    display: inline-flex;
    background: var(--theme-text-primary-on-light);
    opacity: 0.9;
    color: white;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-right: 0.5rem;
}

.injection-properties-dialog__property-row{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.injection-properties-dialog__name-input {
    flex: 2;
}

.injection-properties-dialog__value-input {
    flex: 3;
}

.injection-properties-dialog__icon-block {
    width: 1rem;
}

.injection-properties-dialog__delete {
    display: none;
}

.injection-properties-dialog__property-row:hover .injection-properties-dialog__delete {
    display: inline;
}

.injection-properties-dialog__add-first-property-btn{
    margin-top: 1rem;
}

.injection-properties-dialog__help-link {
    display: inline-block;
    height: 1.25rem;
}