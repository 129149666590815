#htmlLoader {
    width: 100%;
    height: 100%;
}

#htmlLoaderIconWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    margin: .5rem 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}

#htmlLoaderIcon {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ccc;
    width: 40px;
    height: 40px;
    -webkit-animation: rotate 1s ease-in-out infinite forwards;
    animation: rotate 1s ease-in-out infinite forwards;
}