.edit-post-page {
    --edit-page-padding-top: 4rem;
    --edit-post-header-height: 3.75rem;
    --edit-post-buttons-height: 4rem;
    --edit-post-body-height: calc(100vh - var(--edit-page-padding-top) - var(--edit-post-header-height) -
                            var(--edit-post-buttons-height) - 2rem /*padding*/);
    width: 100%;
    flex: 1 0 auto;
    padding: var(--edit-page-padding-top) 0 0;
    max-width: 1300px;
    margin: 0 auto;
}

.edit-post-page__container {
    padding: 1rem;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.edit-post__title-block {
    display: flex;
    gap: 2rem;
    align-items: baseline;
}

.edit-post__title-input {
    flex: 1;
}

.edit-post__body-block {
    flex: 1 0 auto;
    border: 1px solid gray;
    padding: 1rem;
}

.edit-post__body {
    display: none;
}

.edit-post__body[active] {
    display: unset;
    font-size: 1rem;
}

.edit-post__header {
    height: var(--edit-post-header-height);
}

.edit-post__buttons-block {
    height: var(--edit-post-buttons-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit-post__buttons-left-block{
    display: flex;
    gap: 1rem;
}

.edit-post__button {
    color: var(--theme-primary);
}

.edit-post__button[active] {
    border-radius: unset;
    border-bottom: 3px solid var(--theme-primary);
}

.edit-post__button--right {
    float: right;
}

.edit-post__body-input {
    resize: none;
    border: none;
    outline: none;
    height: calc(var(--edit-post-body-height) - 2.2rem);
    width: 100%;
}

.edit-post__body-preview[active] {
    display: block;
    min-height: calc(var(--edit-post-body-height) - 2rem);
    overflow-x: auto;
    padding: 0 1rem;
}

.edit-post__body-example[active] {
    display: flex;
    gap: 1rem;
}

.edit-post__body-example-column {
    width: 50%;
    max-width: 50%;
    overflow-x: auto;
}

.edit-post__body-example-column:last-of-type{
    padding-left: 1rem;
    border-left: 1px solid var(--theme-text-hint-on-light);
}

.edit-post__example-row{
    color: var(--theme-text-hint-on-light);
    display: flex;
    justify-content: space-between;
    height: 1.5rem;
}

.edit-post__example-copy-icon {
    font-size: 1.25rem;
    cursor: pointer;
}

.edit-post__example-copy-icon:hover {
    color: var(--theme-text-primary-on-light);
}

.edit-page__doc-link, .edit-page__doc-link:visited {
    padding-left: 1rem;
    color: var(--theme-primary);
    font-weight: 200;
    font-size: 0.9rem;
}