.asciidoc-box html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%
}

.asciidoc-box a {
    background: none
}

.asciidoc-box a:focus {
    outline: thin dotted
}

.asciidoc-box a:active, .asciidoc-box a:hover {
    outline: 0
}

.asciidoc-box h1 {
    font-size: 2em;
    margin: .67em 0
}

.asciidoc-box b, .asciidoc-box strong {
    font-weight: bold
}

.asciidoc-box abbr {
    font-size: .9em
}

.asciidoc-box abbr[title] {
    cursor: help;
    border-bottom: 1px dotted #dddddf;
    text-decoration: none
}

.asciidoc-box dfn {
    font-style: italic
}

.asciidoc-box hr {
    height: 0
}

.asciidoc-box mark {
    background: #ff0;
    color: #000
}

.asciidoc-box code, .asciidoc-box kbd, .asciidoc-box pre, .asciidoc-box samp {
    font-family: monospace;
    font-size: 1em
}

.asciidoc-box pre {
    white-space: pre-wrap
}

.asciidoc-box q {
    quotes: "\201C" "\201D" "\2018" "\2019"
}

.asciidoc-box small {
    font-size: 80%
}

.asciidoc-box sub, .asciidoc-box sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

.asciidoc-box sup {
    top: -.5em
}

.asciidoc-box sub {
    bottom: -.25em
}

.asciidoc-box img {
    border: 0
}

.asciidoc-box svg:not(:root) {
    overflow: hidden
}

.asciidoc-box figure {
    margin: 0
}

.asciidoc-box audio, .asciidoc-box video {
    display: inline-block
}

.asciidoc-box audio:not([controls]) {
    display: none;
    height: 0
}

.asciidoc-box fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

.asciidoc-box legend {
    border: 0;
    padding: 0
}

.asciidoc-box button, .asciidoc-box input, .asciidoc-box select, .asciidoc-box textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0
}

.asciidoc-box button, .asciidoc-box input {
    line-height: normal
}

.asciidoc-box button, .asciidoc-box select {
    text-transform: none
}

.asciidoc-box button, .asciidoc-box html input[type=button], .asciidoc-box input[type=reset], .asciidoc-box input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

.asciidoc-box button[disabled], .asciidoc-box html input[disabled] {
    cursor: default
}

.asciidoc-box input[type=checkbox], .asciidoc-box input[type=radio] {
    padding: 0
}

.asciidoc-box button::-moz-focus-inner, .asciidoc-box input::-moz-focus-inner {
    border: 0;
    padding: 0
}

.asciidoc-box textarea {
    overflow: auto;
    vertical-align: top
}

.asciidoc-box table {
    border-collapse: collapse;
    border-spacing: 0
}

.asciidoc-box *,
.asciidoc-box ::before, .asciidoc-box ::after {
    box-sizing: border-box
}

.asciidoc-box html, .asciidoc-box body {
    font-size: 100%
}

.asciidoc-box body {
    background: #fff;
    color: rgba(0, 0, 0, .8);
    padding: 0;
    margin: 0;
    font-family: "Noto Serif", "DejaVu Serif", serif;
    line-height: 1;
    position: relative;
    cursor: auto;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    word-wrap: anywhere;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased
}

.asciidoc-box a:hover {
    cursor: pointer
}

.asciidoc-box img, .asciidoc-box object, .asciidoc-box embed {
    max-width: 100%;
    height: auto
}

.asciidoc-box object, .asciidoc-box embed {
    height: 100%
}

.asciidoc-box img {
    -ms-interpolation-mode: bicubic
}

.asciidoc-box .left {
    float: left !important
}

.asciidoc-box .right {
    float: right !important
}

.asciidoc-box .text-left {
    text-align: left !important
}

.asciidoc-box .text-right {
    text-align: right !important
}

.asciidoc-box .text-center {
    text-align: center !important
}

.asciidoc-box .text-justify {
    text-align: justify !important
}

.asciidoc-box .hide {
    display: none
}

.asciidoc-box img, .asciidoc-box object, .asciidoc-box svg {
    display: inline-block;
    vertical-align: middle
}

.asciidoc-box textarea {
    height: auto;
    min-height: 50px
}

.asciidoc-box select {
    width: 100%
}

.asciidoc-box .subheader, .asciidoc-box .admonitionblock td.content > .title, .asciidoc-box .audioblock > .title, .asciidoc-box .exampleblock > .title, .asciidoc-box .imageblock > .title, .asciidoc-box .listingblock > .title, .asciidoc-box .literalblock > .title, .asciidoc-box .stemblock > .title, .asciidoc-box .openblock > .title, .asciidoc-box .paragraph > .title, .asciidoc-box .quoteblock > .title, .asciidoc-box table.tableblock > .title, .asciidoc-box .verseblock > .title, .asciidoc-box .videoblock > .title, .asciidoc-box .dlist > .title, .asciidoc-box .olist > .title, .asciidoc-box .ulist > .title, .asciidoc-box .qlist > .title, .asciidoc-box .hdlist > .title {
    line-height: 1.45;
    color: #7a2518;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: .25em
}

.asciidoc-box div, .asciidoc-box dl, .asciidoc-box dt, .asciidoc-box dd, .asciidoc-box ul, .asciidoc-box ol, .asciidoc-box li, .asciidoc-box h1, .asciidoc-box h2, .asciidoc-box h3, .asciidoc-box #toctitle, .asciidoc-box .sidebarblock > .content > .title, .asciidoc-box h4, .asciidoc-box h5, .asciidoc-box h6, .asciidoc-box pre, .asciidoc-box form, .asciidoc-box p, .asciidoc-box blockquote, .asciidoc-box th, .asciidoc-box td {
    margin: 0;
    padding: 0
}

.asciidoc-box a {
    color: #2156a5;
    text-decoration: underline;
    line-height: inherit
}

.asciidoc-box a:hover, .asciidoc-box a:focus {
    color: #1d4b8f
}

.asciidoc-box a img {
    border: 0
}

.asciidoc-box p {
    line-height: 1.6;
    margin-bottom: 1.25em;
    text-rendering: optimizeLegibility
}

.asciidoc-box p aside {
    font-size: .875em;
    line-height: 1.35;
    font-style: italic
}

.asciidoc-box h1, .asciidoc-box h2, .asciidoc-box h3, .asciidoc-box #toctitle, .asciidoc-box .sidebarblock > .content > .title, .asciidoc-box h4, .asciidoc-box h5, .asciidoc-box h6 {
    font-family: "Open Sans", "DejaVu Sans", sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #ba3925;
    text-rendering: optimizeLegibility;
    margin-top: 1em;
    margin-bottom: .5em;
    line-height: 1.0125em
}

.asciidoc-box h1 .small, .asciidoc-box h2 .small, .asciidoc-box h3 .small, .asciidoc-box #toctitle .small, .asciidoc-box .sidebarblock > .content > .title .small, .asciidoc-box h4 .small, .asciidoc-box h5 .small, .asciidoc-box h6 .small {
    font-size: 60%;
    color: #e99b8f;
    line-height: 0
}

.asciidoc-box h1 {
    font-size: 2.125em
}

.asciidoc-box h2 {
    font-size: 1.6875em
}

.asciidoc-box h3, .asciidoc-box #toctitle, .asciidoc-box .sidebarblock > .content > .title {
    font-size: 1.375em
}

.asciidoc-box h4, .asciidoc-box h5 {
    font-size: 1.125em
}

.asciidoc-box h6 {
    font-size: 1em
}

.asciidoc-box hr {
    border: solid #dddddf;
    border-width: 1px 0 0;
    clear: both;
    margin: 1.25em 0 1.1875em
}

.asciidoc-box em, .asciidoc-box i {
    font-style: italic;
    line-height: inherit
}

.asciidoc-box strong, .asciidoc-box b {
    font-weight: bold;
    line-height: inherit
}

.asciidoc-box small {
    font-size: 60%;
    line-height: inherit
}

.asciidoc-box code {
    font-family: "Droid Sans Mono", "DejaVu Sans Mono", monospace;
    font-weight: 400;
    color: rgba(0, 0, 0, .9)
}

.asciidoc-box ul, .asciidoc-box ol, .asciidoc-box dl {
    line-height: 1.6;
    margin-bottom: 1.25em;
    font-family: inherit;
    list-style: revert;
}

.asciidoc-box ul, .asciidoc-box ol {
    margin-left: 1.5em
}

.asciidoc-box ul .circle, .asciidoc-box ul .disc, .asciidoc-box ul .square, .asciidoc-box ul .circle ul:not([class]), .asciidoc-box ul .disc ul:not([class]), .asciidoc-box ul .square ul:not([class]) {
    list-style: inherit
}

.asciidoc-box ol li ul, .asciidoc-box ol li ol {
    margin-left: 1.25em;
    margin-bottom: 0
}

.asciidoc-box dl dt {
    margin-bottom: .3125em;
    font-weight: bold
}

.asciidoc-box dl dd {
    margin-bottom: 1.25em
}

.asciidoc-box blockquote {
    margin: 0 0 1.25em;
    padding: .5625em 1.25em 0 1.1875em;
    border-left: 1px solid #ddd
}

.asciidoc-box blockquote, .asciidoc-box blockquote p {
    line-height: 1.6;
    color: rgba(0, 0, 0, .85)
}

.asciidoc-box @media screen and (min-width: 768px) {
    .asciidoc-box h1, .asciidoc-box h2, .asciidoc-box h3, .asciidoc-box #toctitle, .asciidoc-box .sidebarblock > .content > .title, .asciidoc-box h4, .asciidoc-box h5, .asciidoc-box h6 {
        line-height: 1.2
    }

    .asciidoc-box h1 {
        font-size: 2.75em
    }

    .asciidoc-box h2 {
        font-size: 2.3125em
    }

    .asciidoc-box h3, .asciidoc-box #toctitle, .asciidoc-box .sidebarblock > .content > .title {
        font-size: 1.6875em
    }

    .asciidoc-box h4 {
        font-size: 1.4375em
    }
}

.asciidoc-box table {
    background: #fff;
    margin-bottom: 1.25em;
    border: 1px solid #dedede;
    word-wrap: normal
}

.asciidoc-box table thead, .asciidoc-box table tfoot {
    background: #f7f8f7
}

.asciidoc-box table thead tr th, .asciidoc-box table thead tr td, .asciidoc-box table tfoot tr th, .asciidoc-box table tfoot tr td {
    padding: .5em .625em .625em;
    font-size: inherit;
    color: rgba(0, 0, 0, .8);
    text-align: left
}

.asciidoc-box table tr th, .asciidoc-box table tr td {
    padding: .5625em .625em;
    font-size: inherit;
    color: rgba(0, 0, 0, .8)
}

.asciidoc-box table tr.even, .asciidoc-box table tr.alt {
    background: #f8f8f7
}

.asciidoc-box table thead tr th, .asciidoc-box table tfoot tr th, .asciidoc-box table tbody tr td, .asciidoc-box table tr td, .asciidoc-box table tfoot tr td {
    line-height: 1.6
}

.asciidoc-box h1, .asciidoc-box h2, .asciidoc-box h3, .asciidoc-box #toctitle, .asciidoc-box .sidebarblock > .content > .title, .asciidoc-box h4, .asciidoc-box h5, .asciidoc-box h6 {
    line-height: 1.2;
    word-spacing: -.05em
}

.asciidoc-box h1 strong, .asciidoc-box h2 strong, .asciidoc-box h3 strong, .asciidoc-box #toctitle strong, .asciidoc-box .sidebarblock > .content > .title strong, .asciidoc-box h4 strong, .asciidoc-box h5 strong, .asciidoc-box h6 strong {
    font-weight: 400
}

.asciidoc-box .center {
    margin-left: auto;
    margin-right: auto
}

.asciidoc-box .stretch {
    width: 100%
}

.asciidoc-box .clearfix::before, .asciidoc-box .clearfix::after, .asciidoc-box .float-group::before, .asciidoc-box .float-group::after {
    content: " ";
    display: table
}

.asciidoc-box .clearfix::after, .asciidoc-box .float-group::after {
    clear: both
}

.asciidoc-box :not(pre).nobreak {
    word-wrap: normal
}

.asciidoc-box :not(pre).nowrap {
    white-space: nowrap
}

.asciidoc-box :not(pre).pre-wrap {
    white-space: pre-wrap
}

.asciidoc-box :not(pre):not([class^=L]) > code {
    font-size: .9375em;
    font-style: normal !important;
    letter-spacing: 0;
    padding: .1em .5ex;
    word-spacing: -.15em;
    background: #f7f7f8;
    border-radius: 4px;
    line-height: 1.45;
    text-rendering: optimizeLegibility
}

.asciidoc-box pre {
    color: rgba(0, 0, 0, .9);
    font-family: "Droid Sans Mono", "DejaVu Sans Mono", monospace;
    line-height: 1.45;
    text-rendering: optimizeSpeed
}

.asciidoc-box pre code, .asciidoc-box pre pre {
    color: inherit;
    font-size: inherit;
    line-height: inherit
}

.asciidoc-box pre > code {
    display: block
}

.asciidoc-box pre.nowrap, .asciidoc-box pre.nowrap pre {
    white-space: pre;
    word-wrap: normal
}

.asciidoc-box em em {
    font-style: normal
}

.asciidoc-box strong strong {
    font-weight: 400
}

.asciidoc-box .keyseq {
    color: rgba(51, 51, 51, .8)
}

.asciidoc-box kbd {
    font-family: "Droid Sans Mono", "DejaVu Sans Mono", monospace;
    display: inline-block;
    color: rgba(0, 0, 0, .8);
    font-size: .65em;
    line-height: 1.45;
    background: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .2), inset 0 0 0 .1em #fff;
    margin: 0 .15em;
    padding: .2em .5em;
    vertical-align: middle;
    position: relative;
    top: -.1em;
    white-space: nowrap
}

.asciidoc-box .keyseq kbd:first-child {
    margin-left: 0
}

.asciidoc-box .keyseq kbd:last-child {
    margin-right: 0
}

.asciidoc-box .menuseq, .asciidoc-box .menuref {
    color: #000
}

.asciidoc-box .menuseq b:not(.caret), .asciidoc-box .menuref {
    /*font-weight: inherit Was deleted because select menu (class menuseq) (a > b > c) should be bold */
}

.asciidoc-box .menuseq {
    word-spacing: -.02em
}

.asciidoc-box .menuseq b.caret {
    font-size: 1.25em;
    line-height: .8
}

.asciidoc-box .menuseq i.caret {
    font-weight: bold;
    text-align: center;
    width: .45em
}

/* We have styles for .menu and want to reset them */
.asciidoc-box .menu{
    position: unset;
    display: unset;
    box-shadow: unset;
    background-color: unset;
}

.asciidoc-box b.button::before, .asciidoc-box b.button::after {
    position: relative;
    top: -1px;
    font-weight: 400
}

.asciidoc-box b.button::before {
    content: "[";
    padding: 0 3px 0 2px
}

.asciidoc-box b.button::after {
    content: "]";
    padding: 0 2px 0 3px
}

.asciidoc-box p a > code:hover {
    color: rgba(0, 0, 0, .9)
}

.asciidoc-box #header, .asciidoc-box #content, .asciidoc-box #footnotes, .asciidoc-box #footer {
    width: 100%;
    margin: 0 auto;
    max-width: 62.5em;
    *zoom: 1;
    position: relative;
    padding-left: .9375em;
    padding-right: .9375em
}

.asciidoc-box #footnotes {
    /* We don't want the notes to be in the center of the post */
    margin: unset;
    max-width: unset;
}

.asciidoc-box #header::before, .asciidoc-box #header::after, .asciidoc-box #content::before, .asciidoc-box #content::after, .asciidoc-box #footnotes::before, .asciidoc-box #footnotes::after, .asciidoc-box #footer::before, .asciidoc-box #footer::after {
    content: " ";
    display: table
}

.asciidoc-box #header::after, .asciidoc-box #content::after, .asciidoc-box #footnotes::after, .asciidoc-box #footer::after {
    clear: both
}

.asciidoc-box #content {
    margin-top: 1.25em
}

.asciidoc-box #content::before {
    content: none
}

.asciidoc-box #header > h1:first-child {
    color: rgba(0, 0, 0, .85);
    margin-top: 2.25rem;
    margin-bottom: 0
}

.asciidoc-box #header > h1:first-child + .asciidoc-box #toc {
    margin-top: 8px;
    border-top: 1px solid #dddddf
}

.asciidoc-box #header > h1:only-child, .asciidoc-box body.toc2 .asciidoc-box #header > h1:nth-last-child(2) {
    border-bottom: 1px solid #dddddf;
    padding-bottom: 8px
}

.asciidoc-box #header .details {
    border-bottom: 1px solid #dddddf;
    line-height: 1.45;
    padding-top: .25em;
    padding-bottom: .25em;
    padding-left: .25em;
    color: rgba(0, 0, 0, .6);
    display: flex;
    flex-flow: row wrap
}

.asciidoc-box #header .details span:first-child {
    margin-left: -.125em
}

.asciidoc-box #header .details span.email a {
    color: rgba(0, 0, 0, .85)
}

.asciidoc-box #header .details br {
    display: none
}

.asciidoc-box #header .details br + span::before {
    content: "\00a0\2013\00a0"
}

.asciidoc-box #header .details br + span.author::before {
    content: "\00a0\22c5\00a0";
    color: rgba(0, 0, 0, .85)
}

.asciidoc-box #header .details br + span#revremark::before {
    content: "\00a0|\00a0"
}

.asciidoc-box #header #revnumber {
    text-transform: capitalize
}

.asciidoc-box #header #revnumber::after {
    content: "\00a0"
}

.asciidoc-box #content > h1:first-child:not([class]) {
    color: rgba(0, 0, 0, .85);
    border-bottom: 1px solid #dddddf;
    padding-bottom: 8px;
    margin-top: 0;
    padding-top: 1rem;
    margin-bottom: 1.25rem
}

.asciidoc-box #toc {
    border-bottom: 1px solid #e7e7e9;
    padding-bottom: .5em
}

.asciidoc-box #toc > ul {
    margin-left: .125em
}

.asciidoc-box #toc ul.sectlevel0 > li > a {
    font-style: italic
}

.asciidoc-box #toc ul.sectlevel0 ul.sectlevel1 {
    margin: .5em 0
}

.asciidoc-box #toc ul {
    font-family: "Open Sans", "DejaVu Sans", sans-serif;
    list-style-type: none
}

.asciidoc-box #toc li {
    line-height: 1.3334;
    margin-top: .3334em
}

.asciidoc-box #toc a {
    text-decoration: none
}

.asciidoc-box #toc a:active {
    text-decoration: underline
}

.asciidoc-box #toctitle {
    color: #7a2518;
    font-size: 1.2em
}

.asciidoc-box @media screen and (min-width: 768px) {
    .asciidoc-box #toctitle {
        font-size: 1.375em
    }

    .asciidoc-box body.toc2 {
        padding-left: 15em;
        padding-right: 0
    }

    .asciidoc-box #toc.toc2 {
        margin-top: 0 !important;
        background: #f8f8f7;
        position: fixed;
        width: 15em;
        left: 0;
        top: 0;
        border-right: 1px solid #e7e7e9;
        border-top-width: 0 !important;
        border-bottom-width: 0 !important;
        z-index: 1000;
        padding: 1.25em 1em;
        height: 100%;
        overflow: auto
    }

    .asciidoc-box #toc.toc2 #toctitle {
        margin-top: 0;
        margin-bottom: .8rem;
        font-size: 1.2em
    }

    .asciidoc-box #toc.toc2 > ul {
        font-size: .9em;
        margin-bottom: 0
    }

    .asciidoc-box #toc.toc2 ul ul {
        margin-left: 0;
        padding-left: 1em
    }

    .asciidoc-box #toc.toc2 ul.sectlevel0 ul.sectlevel1 {
        padding-left: 0;
        margin-top: .5em;
        margin-bottom: .5em
    }

    .asciidoc-box body.toc2.toc-right {
        padding-left: 0;
        padding-right: 15em
    }

    .asciidoc-box body.toc2.toc-right .asciidoc-box #toc.toc2 {
        border-right-width: 0;
        border-left: 1px solid #e7e7e9;
        left: auto;
        right: 0
    }
}

.asciidoc-box @media screen and (min-width: 1280px) {
    .asciidoc-box body.toc2 {
        padding-left: 20em;
        padding-right: 0
    }

    .asciidoc-box #toc.toc2 {
        width: 20em
    }

    .asciidoc-box #toc.toc2 #toctitle {
        font-size: 1.375em
    }

    .asciidoc-box #toc.toc2 > ul {
        font-size: .95em
    }

    .asciidoc-box #toc.toc2 ul ul {
        padding-left: 1.25em
    }

    .asciidoc-box body.toc2.toc-right {
        padding-left: 0;
        padding-right: 20em
    }
}

.asciidoc-box #content .asciidoc-box #toc {
    border: 1px solid #e0e0dc;
    margin-bottom: 1.25em;
    padding: 1.25em;
    background: #f8f8f7;
    border-radius: 4px
}

.asciidoc-box #content .asciidoc-box #toc > :first-child {
    margin-top: 0
}

.asciidoc-box #content .asciidoc-box #toc > :last-child {
    margin-bottom: 0
}

.asciidoc-box #footer {
    max-width: none;
    background: rgba(0, 0, 0, .8);
    padding: 1.25em
}

.asciidoc-box #footer-text {
    color: hsla(0, 0%, 100%, .8);
    line-height: 1.44
}

.asciidoc-box #content {
    margin-bottom: .625em
}

.asciidoc-box .sect1 {
    padding-bottom: .625em
}

.asciidoc-box @media screen and (min-width: 768px) {
    .asciidoc-box #content {
        margin-bottom: 1.25em
    }

    .asciidoc-box .sect1 {
        padding-bottom: 1.25em
    }
}

.asciidoc-box .sect1:last-child {
    padding-bottom: 0
}

.asciidoc-box .sect1 + .asciidoc-box .sect1 {
    border-top: 1px solid #e7e7e9
}

.asciidoc-box #content .asciidoc-box h1 > a.anchor, .asciidoc-box h2 > a.anchor, .asciidoc-box h3 > a.anchor, .asciidoc-box #toctitle > a.anchor, .asciidoc-box .sidebarblock > .content > .title > a.anchor, .asciidoc-box h4 > a.anchor, .asciidoc-box h5 > a.anchor, .asciidoc-box h6 > a.anchor {
    position: absolute;
    z-index: 1001;
    width: 1.5ex;
    margin-left: -1.5ex;
    display: block;
    text-decoration: none !important;
    visibility: hidden;
    text-align: center;
    font-weight: 400
}

.asciidoc-box #content .asciidoc-box h1 > a.anchor::before, .asciidoc-box h2 > a.anchor::before, .asciidoc-box h3 > a.anchor::before, .asciidoc-box #toctitle > a.anchor::before, .asciidoc-box .sidebarblock > .content > .title > a.anchor::before, .asciidoc-box h4 > a.anchor::before, .asciidoc-box h5 > a.anchor::before, .asciidoc-box h6 > a.anchor::before {
    content: "\00A7";
    font-size: .85em;
    display: block;
    padding-top: .1em
}

.asciidoc-box #content .asciidoc-box h1:hover > a.anchor, .asciidoc-box #content .asciidoc-box h1 > a.anchor:hover, .asciidoc-box h2:hover > a.anchor, .asciidoc-box h2 > a.anchor:hover, .asciidoc-box h3:hover > a.anchor, .asciidoc-box #toctitle:hover > a.anchor, .asciidoc-box .sidebarblock > .content > .title:hover > a.anchor, .asciidoc-box h3 > a.anchor:hover, .asciidoc-box #toctitle > a.anchor:hover, .asciidoc-box .sidebarblock > .content > .title > a.anchor:hover, .asciidoc-box h4:hover > a.anchor, .asciidoc-box h4 > a.anchor:hover, .asciidoc-box h5:hover > a.anchor, .asciidoc-box h5 > a.anchor:hover, .asciidoc-box h6:hover > a.anchor, .asciidoc-box h6 > a.anchor:hover {
    visibility: visible
}

.asciidoc-box #content .asciidoc-box h1 > a.link, .asciidoc-box h2 > a.link, .asciidoc-box h3 > a.link, .asciidoc-box #toctitle > a.link, .asciidoc-box .sidebarblock > .content > .title > a.link, .asciidoc-box h4 > a.link, .asciidoc-box h5 > a.link, .asciidoc-box h6 > a.link {
    color: #ba3925;
    text-decoration: none
}

.asciidoc-box #content .asciidoc-box h1 > a.link:hover, .asciidoc-box h2 > a.link:hover, .asciidoc-box h3 > a.link:hover, .asciidoc-box #toctitle > a.link:hover, .asciidoc-box .sidebarblock > .content > .title > a.link:hover, .asciidoc-box h4 > a.link:hover, .asciidoc-box h5 > a.link:hover, .asciidoc-box h6 > a.link:hover {
    color: #a53221
}

.asciidoc-box details, .asciidoc-box .audioblock, .asciidoc-box .imageblock, .asciidoc-box .literalblock, .asciidoc-box .listingblock, .asciidoc-box .stemblock, .asciidoc-box .videoblock {
    margin-bottom: 1.25em
}

.asciidoc-box details {
    margin-left: 1.25rem
}

.asciidoc-box details > summary {
    cursor: pointer;
    display: block;
    position: relative;
    line-height: 1.6;
    margin-bottom: .625rem;
    outline: none;
    -webkit-tap-highlight-color: transparent
}

.asciidoc-box details > summary::-webkit-details-marker {
    display: none
}

.asciidoc-box details > summary::before {
    content: "";
    border: solid transparent;
    border-left: solid;
    border-width: .3em 0 .3em .5em;
    position: absolute;
    top: .5em;
    left: -1.25rem;
    transform: translateX(15%)
}

.asciidoc-box details[open] > summary::before {
    border: solid transparent;
    border-top: solid;
    border-width: .5em .3em 0;
    transform: translateY(15%)
}

.asciidoc-box details > summary::after {
    content: "";
    width: 1.25rem;
    height: 1em;
    position: absolute;
    top: .3em;
    left: -1.25rem
}

.asciidoc-box .admonitionblock td.content>.title, .asciidoc-box .audioblock>.title, .asciidoc-box .exampleblock>.title, .asciidoc-box .listingblock>.title, .asciidoc-box .literalblock>.title, .asciidoc-box .stemblock>.title, .asciidoc-box .openblock>.title, .asciidoc-box .paragraph>.title, .asciidoc-box .quoteblock>.title, .asciidoc-box table.tableblock>.title, .asciidoc-box .verseblock>.title, .asciidoc-box .videoblock>.title, .asciidoc-box .dlist>.title, .asciidoc-box .olist>.title, .asciidoc-box .ulist>.title, .asciidoc-box .qlist>.title, .asciidoc-box .hdlist>.title {
    text-rendering: optimizeLegibility;
    text-align: left;
    font-family: "Noto Serif", "DejaVu Serif", serif;
    font-size: 1rem;
    font-style: italic;
}

.asciidoc-box .imageblock>.title {
    text-rendering: optimizeLegibility;
    font-family: "Noto Serif", "DejaVu Serif", serif;
    font-size: 1rem;
    font-style: italic;
}

.asciidoc-box table.tableblock.fit-content>caption.title {
    white-space: nowrap;
    width: 0;
}

.asciidoc-box .paragraph.lead>p, .asciidoc-box #preamble>.sectionbody>[class=paragraph]:first-of-type p {
    font-size: 1.21875em;
    line-height: 1.6;
    color: rgba(0, 0, 0, .85);
}

.asciidoc-box .admonitionblock>table {
    border-collapse: separate;
    border: 0;
    background: none;
    width: 100%;
}

.asciidoc-box .admonitionblock>table td.icon {
    text-align: center;
    min-width: 80px;
}

.asciidoc-box .admonitionblock>table td.icon img {
    max-width: none;
}

.asciidoc-box .admonitionblock>table td.icon .title {
    font-family: "Open Sans", "DejaVu Sans", sans-serif;
    text-transform: uppercase;
}

.asciidoc-box .admonitionblock>table td.content {
    padding-left: 1.125em;
    padding-right: 1.25em;
    color: rgba(0, 0, 0, .6);
    word-wrap: anywhere;
}

.asciidoc-box .admonitionblock>table td.content>:last-child>:last-child {
    margin-bottom: 0;
}

.asciidoc-box .admonitionblock {
    margin: 1.4rem 0 0
}

.asciidoc-box .admonitionblock p, .asciidoc-box .admonitionblock td.content {
    font-size: .88889rem
}

.asciidoc-box .admonitionblock td.content > .title + *, .asciidoc-box .admonitionblock td.content > :not(.title):first-child {
    margin-top: 0
}

.asciidoc-box .admonitionblock td.content pre {
    font-size: .83333rem
}

.asciidoc-box .admonitionblock > table {
    table-layout: fixed;
    position: relative;
    width: 100%
}

.asciidoc-box .admonitionblock td.content {
    padding: 1rem 1rem .75rem;
    background: #fafafa;
    width: 100%;
    word-wrap: anywhere
}

.asciidoc-box .admonitionblock td.icon {
    font-size: .83333rem;
    left: 0;
    line-height: 1;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translate(-.5rem, -50%);
    transform: translate(-.5rem, -50%)
}

.asciidoc-box .admonitionblock td.icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    border-radius: .45rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-filter: initial;
    filter: none;
    height: 1.25rem;
    padding: 0 .5rem;
    vertical-align: initial;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.asciidoc-box .admonitionblock td.icon i::after {
    content: attr(title);
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase
}

.asciidoc-box .admonitionblock.caution td.icon {
    background-color: #a0439c;
    color: #fff
}

.asciidoc-box .admonitionblock.important td.icon {
    background-color: #d32f2f;
    color: #fff
}

.asciidoc-box .admonitionblock.note td.icon {
    background-color: #217ee7;
    color: #fff
}

.asciidoc-box .admonitionblock.tip td.icon {
    background-color: #41af46;
    color: #fff
}

.asciidoc-box .admonitionblock.warning td.icon {
    background-color: #e18114;
    color: #fff
}


.asciidoc-box .exampleblock>.content {
    border: 1px solid #e6e6e6;
    margin-bottom: 1.25em;
    padding: 1.25em;
    background: #fff;
    border-radius: 4px;
}

.asciidoc-box .sidebarblock {
    border: 1px solid #dbdbd6;
    margin-bottom: 1.25em;
    padding: 1.25em;
    background: #f3f3f2;
    border-radius: 4px;
}

.asciidoc-box .sidebarblock>.content>.title {
    color: #7a2518;
    margin-top: 0;
    text-align: center;
}

.asciidoc-box .exampleblock>.content>:first-child, .asciidoc-box .sidebarblock>.content>:first-child {
    margin-top: 0;
}

.asciidoc-box .exampleblock>.content>:last-child, .asciidoc-box .exampleblock>.content>:last-child>:last-child, .asciidoc-box .exampleblock>.content .olist>ol>li:last-child>:last-child, .asciidoc-box .exampleblock>.content .ulist>ul>li:last-child>:last-child, .asciidoc-box .exampleblock>.content .qlist>ol>li:last-child>:last-child, .asciidoc-box .sidebarblock>.content>:last-child, .asciidoc-box .sidebarblock>.content>:last-child>:last-child, .asciidoc-box .sidebarblock>.content .olist>ol>li:last-child>:last-child, .asciidoc-box .sidebarblock>.content .ulist>ul>li:last-child>:last-child, .asciidoc-box .sidebarblock>.content .qlist>ol>li:last-child>:last-child {
    margin-bottom: 0;
}

.asciidoc-box .literalblock pre, .asciidoc-box .listingblock>.content>pre {
    border-radius: 4px;
    overflow-x: auto;
    padding: 1em;
    font-size: .8125em;
}



@media screen and (min-width: 768px) {
    .asciidoc-box .literalblock pre, .asciidoc-box .listingblock>.content>pre {
        font-size: .90625em;
    }
}

@media screen and (min-width: 1280px) {
    .asciidoc-box .literalblock pre, .asciidoc-box .listingblock>.content>pre {
        font-size: 1em;
    }
}

.asciidoc-box .literalblock pre, .asciidoc-box .listingblock>.content>pre:not(.highlight), .asciidoc-box .listingblock>.content>pre[class=highlight], .asciidoc-box .listingblock>.content>pre[class^="highlight "] {
    background: #f7f7f8;
}

.asciidoc-box .literalblock.output pre {
    color: #f7f7f8;
    background: rgba(0, 0, 0, .9);
}

.asciidoc-box .listingblock>.content {
    position: relative;
}

.asciidoc-box .listingblock code[data-lang]::before {
    display: none;
    content: attr(data-lang);
    position: absolute;
    font-size: .75em;
    top: .425rem;
    right: .5rem;
    line-height: 1;
    text-transform: uppercase;
    color: inherit;
    opacity: .5;
}

.asciidoc-box .listingblock:hover code[data-lang]::before {
    display: block;
}

.asciidoc-box .listingblock.terminal pre .command::before {
    content: attr(data-prompt);
    padding-right: .5em;
    color: inherit;
    opacity: .5;
}

.asciidoc-box .listingblock.terminal pre .command:not([data-prompt])::before {
    content: "$";
}

.asciidoc-box .listingblock pre.highlightjs {
    padding: 0;
}

.asciidoc-box .listingblock pre.highlightjs>code {
    padding: 1em;
    border-radius: 4px;
}

.asciidoc-box .listingblock pre.prettyprint {
    border-width: 0;
}

.asciidoc-box .prettyprint {
    background: #f7f7f8;
}

.asciidoc-box pre.prettyprint .linenums {
    line-height: 1.45;
    margin-left: 2em;
}

.asciidoc-box pre.prettyprint li {
    background: none;
    list-style-type: inherit;
    padding-left: 0;
}

.asciidoc-box pre.prettyprint li code[data-lang]::before {
    opacity: 1;
}

.asciidoc-box pre.prettyprint li:not(:first-child) code[data-lang]::before {
    display: none;
}

.asciidoc-box table.linenotable {
    border-collapse: separate;
    border: 0;
    margin-bottom: 0;
    background: none;
}

.asciidoc-box table.linenotable td[class] {
    color: inherit;
    vertical-align: top;
    padding: 0;
    line-height: inherit;
    white-space: normal;
}

.asciidoc-box table.linenotable td.code {
    padding-left: .75em;
}

.asciidoc-box table.linenotable td.linenos, .asciidoc-box pre.pygments .linenos {
    border-right: 1px solid;
    opacity: .35;
    padding-right: .5em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.asciidoc-box pre.pygments span.linenos {
    display: inline-block;
    margin-right: .75em;
}





.asciidoc-box .quoteblock {
    margin: 0 1em 1.25em 1.5em;
    display: table;
}

.asciidoc-box .quoteblock:not(.excerpt)>.title {
    margin-left: -1.5em;
    margin-bottom: .75em;
}

.asciidoc-box .quoteblock blockquote, .asciidoc-box .quoteblock p {
    color: rgba(0, 0, 0, .85);
    font-size: 1.15rem;
    line-height: 1.75;
    word-spacing: .1em;
    letter-spacing: 0;
    font-style: italic;
    text-align: justify;
}

.asciidoc-box .quoteblock blockquote {
    margin: 0;
    padding: 0;
    border: 0;
}

.asciidoc-box .quoteblock blockquote::before {
    content: "\201c";
    float: left;
    font-size: 2.75em;
    font-weight: bold;
    line-height: .6em;
    margin-left: -.6em;
    color: #7a2518;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

.asciidoc-box .quoteblock blockquote>.paragraph:last-child p {
    margin-bottom: 0;
}

.asciidoc-box .quoteblock .attribution {
    margin-top: .75em;
    margin-right: .5ex;
    text-align: right;
}

.asciidoc-box .verseblock {
    margin: 0 1em 1.25em;
}

.asciidoc-box .verseblock pre {
    font-family: "Open Sans", "DejaVu Sans", sans-serif;
    font-size: 1.15rem;
    color: rgba(0, 0, 0, .85);
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

.asciidoc-box .verseblock pre strong {
    font-weight: 400;
}

.asciidoc-box .verseblock .attribution {
    margin-top: 1.25rem;
    margin-left: .5ex;
}

.asciidoc-box .quoteblock .attribution, .asciidoc-box .verseblock .attribution {
    font-size: .9375em;
    line-height: 1.45;
    font-style: italic;
}

.asciidoc-box .quoteblock .attribution br, .asciidoc-box .verseblock .attribution br {
    display: none;
}

.asciidoc-box .quoteblock .attribution cite, .asciidoc-box .verseblock .attribution cite {
    display: block;
    letter-spacing: -.025em;
    color: rgba(0, 0, 0, .6);
}

.asciidoc-box .quoteblock.abstract blockquote::before, .asciidoc-box .quoteblock.excerpt blockquote::before, .asciidoc-box .quoteblock .quoteblock blockquote::before {
    display: none;
}

.asciidoc-box .quoteblock.abstract blockquote, .asciidoc-box .quoteblock.abstract p, .asciidoc-box .quoteblock.excerpt blockquote, .asciidoc-box .quoteblock.excerpt p, .asciidoc-box .quoteblock .quoteblock blockquote, .asciidoc-box .quoteblock .quoteblock p {
    line-height: 1.6;
    word-spacing: 0;
}

.asciidoc-box .quoteblock.abstract {
    margin: 0 1em 1.25em;
    display: block;
}

.asciidoc-box .quoteblock.abstract>.title {
    margin: 0 0 .375em;
    font-size: 1.15em;
    text-align: center;
}

.asciidoc-box .quoteblock.excerpt>blockquote, .asciidoc-box .quoteblock .quoteblock {
    padding: 0 0 .25em 1em;
    border-left: .25em solid #dddddf;
}

.asciidoc-box .quoteblock.excerpt, .asciidoc-box .quoteblock .quoteblock {
    margin-left: 0;
}

.asciidoc-box .quoteblock.excerpt blockquote, .asciidoc-box .quoteblock.excerpt p, .asciidoc-box .quoteblock .quoteblock blockquote, .asciidoc-box .quoteblock .quoteblock p {
    color: inherit;
    font-size: 1.0625rem;
}

.asciidoc-box .quoteblock.excerpt .attribution, .asciidoc-box .quoteblock .quoteblock .attribution {
    color: inherit;
    font-size: .85rem;
    text-align: left;
    margin-right: 0;
}

.asciidoc-box p.tableblock:last-child {
    margin-bottom: 0;
}

.asciidoc-box td.tableblock>.content {
    margin-bottom: 1.25em;
    word-wrap: anywhere;
}

.asciidoc-box td.tableblock>.content>:last-child {
    margin-bottom: -1.25em;
}

.asciidoc-box table.tableblock, .asciidoc-box th.tableblock, .asciidoc-box td.tableblock {
    border: 0 solid #dedede;
}

.asciidoc-box table.grid-all>*>tr>* {
    border-width: 1px;
}

.asciidoc-box table.grid-cols>*>tr>* {
    border-width: 0 1px;
}

.asciidoc-box table.grid-rows>*>tr>* {
    border-width: 1px 0;
}

.asciidoc-box table.frame-all {
    border-width: 1px;
}

.asciidoc-box table.frame-ends {
    border-width: 1px 0;
}

.asciidoc-box table.frame-sides {
    border-width: 0 1px;
}

.asciidoc-box table.frame-none>colgroup+*>:first-child>*, .asciidoc-box table.frame-sides>colgroup+*>:first-child>* {
    border-top-width: 0;
}

.asciidoc-box table.frame-none>:last-child>:last-child>*, .asciidoc-box table.frame-sides>:last-child>:last-child>* {
    border-bottom-width: 0;
}

.asciidoc-box table.frame-none>*>tr>:first-child, .asciidoc-box table.frame-ends>*>tr>:first-child {
    border-left-width: 0;
}

.asciidoc-box table.frame-none>*>tr>:last-child, .asciidoc-box table.frame-ends>*>tr>:last-child {
    border-right-width: 0;
}

.asciidoc-box table.stripes-all>*>tr, .asciidoc-box table.stripes-odd>*>tr:nth-of-type(odd), .asciidoc-box table.stripes-even>*>tr:nth-of-type(even), .asciidoc-box table.stripes-hover>*>tr:hover {
    background: #f8f8f7;
}

.asciidoc-box th.halign-left, .asciidoc-box td.halign-left {
    text-align: left;
}

.asciidoc-box th.halign-right, .asciidoc-box td.halign-right {
    text-align: right;
}

.asciidoc-box th.halign-center, .asciidoc-box td.halign-center {
    text-align: center;
}

.asciidoc-box th.valign-top, .asciidoc-box td.valign-top {
    vertical-align: top;
}

.asciidoc-box th.valign-bottom, .asciidoc-box td.valign-bottom {
    vertical-align: bottom;
}

.asciidoc-box th.valign-middle, .asciidoc-box td.valign-middle {
    vertical-align: middle;
}

.asciidoc-box table thead th, .asciidoc-box table tfoot th {
    font-weight: bold;
}

.asciidoc-box tbody tr th {
    background: #f7f8f7;
}

.asciidoc-box tbody tr th, .asciidoc-box tbody tr th p, .asciidoc-box tfoot tr th, .asciidoc-box tfoot tr th p {
    color: rgba(0, 0, 0, .8);
    font-weight: bold;
}

.asciidoc-box p.tableblock>code:only-child {
    background: none;
    padding: 0;
}

.asciidoc-box p.tableblock {
    font-size: 1em;
}

.asciidoc-box ol {
    margin-left: 1.75em;
}

.asciidoc-box ul li ol {
    margin-left: 1.5em;
}

.asciidoc-box dl dd {
    margin-left: 1.125em;
}

.asciidoc-box dl dd:last-child, .asciidoc-box dl dd:last-child>:last-child {
    margin-bottom: 0;
}

.asciidoc-box li p, .asciidoc-box ul dd, .asciidoc-box ol dd, .asciidoc-box .olist .olist, .asciidoc-box .ulist .ulist, .asciidoc-box .ulist .olist, .asciidoc-box .olist .ulist {
    margin-bottom: .625em;
}

.asciidoc-box li p {
    margin-bottom: 0.225em;
}

.asciidoc-box ul.checklist, .asciidoc-box ul.none, .asciidoc-box ol.none, .asciidoc-box ul.no-bullet, .asciidoc-box ol.no-bullet, .asciidoc-box ol.unnumbered, .asciidoc-box ul.unstyled, .asciidoc-box ol.unstyled {
    list-style-type: none;
}

.asciidoc-box ul.no-bullet, .asciidoc-box ol.no-bullet, .asciidoc-box ol.unnumbered {
    margin-left: .625em;
}

.asciidoc-box ul.unstyled, .asciidoc-box ol.unstyled {
    margin-left: 0;
}

.asciidoc-box li>p:empty:only-child::before {
    content: "";
    display: inline-block;
}

.asciidoc-box ul.checklist>li>p:first-child {
    margin-left: -1em;
}

.asciidoc-box ul.checklist>li>p:first-child>.fa-square-o:first-child, .asciidoc-box ul.checklist>li>p:first-child>.fa-check-square-o:first-child {
    width: 1.25em;
    font-size: .8em;
    position: relative;
    bottom: .125em;
}

.asciidoc-box ul.checklist>li>p:first-child>input[type=checkbox]:first-child {
    margin-right: .25em;
}

.asciidoc-box ul.inline {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 0 0 .625em -1.25em;
}

.asciidoc-box ul.inline>li {
    margin-left: 1.25em;
}

.asciidoc-box .unstyled dl dt {
    font-weight: 400;
    font-style: normal;
}




.asciidoc-box ol.arabic {
    list-style-type: decimal;
}

.asciidoc-box ol.decimal {
    list-style-type: decimal-leading-zero;
}

.asciidoc-box ol.loweralpha {
    list-style-type: lower-alpha;
}

.asciidoc-box ol.upperalpha {
    list-style-type: upper-alpha;
}

.asciidoc-box ol.lowerroman {
    list-style-type: lower-roman;
}

.asciidoc-box ol.upperroman {
    list-style-type: upper-roman;
}

.asciidoc-box ol.lowergreek {
    list-style-type: lower-greek;
}

.asciidoc-box .hdlist>table, .asciidoc-box .colist>table {
    border: 0;
    background: none;
}

.asciidoc-box .hdlist>table>tbody>tr, .asciidoc-box .colist>table>tbody>tr {
    background: none;
}

.asciidoc-box td.hdlist1, .asciidoc-box td.hdlist2 {
    vertical-align: top;
    padding: 0 .625em;
}

.asciidoc-box td.hdlist1 {
    font-weight: bold;
    padding-bottom: 1.25em;
}

.asciidoc-box td.hdlist2 {
    word-wrap: anywhere;
}

.asciidoc-box .literalblock+.colist, .asciidoc-box .listingblock+.colist {
    margin-top: -.5em;
}

.asciidoc-box .colist td:not([class]):first-child {
    padding: .4em .75em 0;
    line-height: 1;
    vertical-align: top;
}

.asciidoc-box .colist td:not([class]):first-child img {
    max-width: none;
}

.asciidoc-box .colist td:not([class]):last-child {
    padding: .25em 0;
}

.asciidoc-box .thumb, .asciidoc-box .th {
    line-height: 0;
    display: inline-block;
    border: 4px solid #fff;
    box-shadow: 0 0 0 1px #ddd;
}

.asciidoc-box .imageblock.left {
    margin: .25em .625em 1.25em 0;
}

.asciidoc-box .imageblock.right {
    margin: .25em 0 1.25em .625em;
}

.asciidoc-box .imageblock>.title {
    margin-bottom: 0;
}

.asciidoc-box .imageblock.thumb, .asciidoc-box .imageblock.th {
    border-width: 6px;
}

.asciidoc-box .imageblock.thumb>.title, .asciidoc-box .imageblock.th>.title {
    padding: 0 .125em;
}

.asciidoc-box .image.left, .asciidoc-box .image.right {
    margin-top: .25em;
    margin-bottom: .25em;
    display: inline-block;
    line-height: 0;
}

.asciidoc-box .image.left {
    margin-right: .625em;
}

.asciidoc-box .image.right {
    margin-left: .625em;
}

.asciidoc-box a.image {
    text-decoration: none;
    display: inline-block;
}

.asciidoc-box a.image object {
    pointer-events: none;
}

.asciidoc-box sup.footnote, .asciidoc-box sup.footnoteref {
    font-size: .875em;
    position: static;
    vertical-align: super;
}

.asciidoc-box sup.footnote a, .asciidoc-box sup.footnoteref a {
    text-decoration: none;
}

.asciidoc-box sup.footnote a:active, .asciidoc-box sup.footnoteref a:active {
    text-decoration: underline;
}

.asciidoc-box #footnotes {
    padding-top: .75em;
    padding-bottom: .75em;
    margin-bottom: .625em;
}

.asciidoc-box #footnotes hr {
    width: 20%;
    min-width: 6.25em;
    margin: -.25em 0 .75em;
    border-width: 1px 0 0;
}

.asciidoc-box #footnotes .footnote {
    padding: 0 .375em 0 .225em;
    line-height: 1.3334;
    font-size: .875em;
    margin-left: 1.2em;
    margin-bottom: .2em;
}

.asciidoc-box #footnotes .footnote a:first-of-type {
    font-weight: bold;
    text-decoration: none;
    margin-left: -1.05em;
}

.asciidoc-box #footnotes .footnote:last-of-type {
    margin-bottom: 0;
}

.asciidoc-box #content #footnotes {
    margin-top: -.625em;
    margin-bottom: 0;
    padding: .75em 0;
}



.asciidoc-box div.unbreakable {
    page-break-inside: avoid;
}

.asciidoc-box .big {
    font-size: larger;
}

.asciidoc-box .small {
    font-size: smaller;
}

.asciidoc-box .underline {
    text-decoration: underline;
}

.asciidoc-box .overline {
    text-decoration: overline;
}

.asciidoc-box .line-through {
    text-decoration: line-through;
}

.asciidoc-box .aqua {
    color: #00bfbf;
}

.asciidoc-box .aqua-background {
    background: #00fafa;
}

.asciidoc-box .black {
    color: #000;
}

.asciidoc-box .black-background {
    background: #000;
}

.asciidoc-box .blue {
    color: #0000bf;
}

.asciidoc-box .blue-background {
    background: #0000fa;
}

.asciidoc-box .fuchsia {
    color: #bf00bf;
}

.asciidoc-box .fuchsia-background {
    background: #fa00fa;
}

.asciidoc-box .gray {
    color: #606060;
}

.asciidoc-box .gray-background {
    background: #7d7d7d;
}

.asciidoc-box .green {
    color: #006000;
}

.asciidoc-box .green-background {
    background: #007d00;
}

.asciidoc-box .lime {
    color: #00bf00;
}

.asciidoc-box .lime-background {
    background: #00fa00;
}

.asciidoc-box .maroon {
    color: #600000;
}

.asciidoc-box .maroon-background {
    background: #7d0000;
}

.asciidoc-box .navy {
    color: #000060;
}

.asciidoc-box .navy-background {
    background: #00007d;
}

.asciidoc-box .olive {
    color: #606000;
}

.asciidoc-box .olive-background {
    background: #7d7d00;
}

.asciidoc-box .purple {
    color: #600060;
}

.asciidoc-box .purple-background {
    background: #7d007d;
}

.asciidoc-box .red {
    color: #bf0000;
}

.asciidoc-box .red-background {
    background: #fa0000;
}

.asciidoc-box .silver {
    color: #909090;
}

.asciidoc-box .silver-background {
    background: #bcbcbc;
}

.asciidoc-box .teal {
    color: #006060;
}

.asciidoc-box .teal-background {
    background: #007d7d;
}

.asciidoc-box .white {
    color: #bfbfbf;
}

.asciidoc-box .white-background {
    background: #fafafa;
}

.asciidoc-box .yellow {
    color: #bfbf00;
}

.asciidoc-box .yellow-background {
    background: #fafa00;
}

.asciidoc-box span.icon>.fa {
    cursor: default;
}

.asciidoc-box a span.icon>.fa {
    cursor: inherit;
}

.asciidoc-box .admonitionblock td.icon [class^="fa icon-"] {
    font-size: 2.5em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
    cursor: default;
}

.asciidoc-box .admonitionblock td.icon .icon-note::before {
    content: "\f05a";
    color: #19407c;
}

.asciidoc-box .admonitionblock td.icon .icon-tip::before {
    content: "\f0eb";
    text-shadow: 1px 1px 2px rgba(155, 155, 0, .8);
    color: #111;
}

.asciidoc-box .admonitionblock td.icon .icon-warning::before {
    content: "\f071";
    color: #bf6900;
}

.asciidoc-box .admonitionblock td.icon .icon-caution::before {
    content: "\f06d";
    color: #bf3400;
}

.asciidoc-box .admonitionblock td.icon .icon-important::before {
    content: "\f06a";
    color: #bf0000;
}

.asciidoc-box .conum[data-value] {
    display: inline-block;
    color: #fff!important;
    background: rgba(0, 0, 0, .8);
    border-radius: 50%;
    text-align: center;
    font-size: .75em;
    width: 1.67em;
    height: 1.67em;
    line-height: 1.67em;
    font-family: "Open Sans", "DejaVu Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
}

.asciidoc-box .conum[data-value] * {
    color: #fff!important;
}

.asciidoc-box .conum[data-value]+b {
    display: none;
}

.asciidoc-box .conum[data-value]::after {
    content: attr(data-value);
}

.asciidoc-box pre .conum[data-value] {
    position: relative;
    top: -.125em;
}

.asciidoc-box b.conum * {
    color: inherit!important;
}

.asciidoc-box .conum:not([data-value]):empty {
    display: none;
}

.asciidoc-box dt, .asciidoc-box th.tableblock, .asciidoc-box td.content, .asciidoc-box div.footnote {
    text-rendering: optimizeLegibility;
}

.asciidoc-box h1, .asciidoc-box h2, .asciidoc-box p, .asciidoc-box td.content, .asciidoc-box span.alt, .asciidoc-box summary {
    letter-spacing: -.01em;
}

.asciidoc-box p strong, .asciidoc-box td.content strong, .asciidoc-box div.footnote strong {
    letter-spacing: -.005em;
}

.asciidoc-box p, .asciidoc-box blockquote, .asciidoc-box dt, .asciidoc-box td.content, .asciidoc-box td.hdlist1, .asciidoc-box span.alt, .asciidoc-box summary {
    font-size: 1.0625rem;
}

.asciidoc-box p {
    margin-bottom: 1.25rem;
}

.asciidoc-box .sidebarblock p, .asciidoc-box .sidebarblock dt, .asciidoc-box .sidebarblock td.content, .asciidoc-box p.tableblock {
    font-size: 1em;
}

.asciidoc-box .exampleblock>.content {
    background: #fffef7;
    border-color: #e0e0dc;
    box-shadow: 0 1px 4px #e0e0dc;
}

.asciidoc-box .print-only {
    display: none!important;
}



.asciidoc-box @page {
    margin: 1.25cm .75cm;
}

.asciidoc-box @media print {
    * {
        box-shadow: none !important;
        text-shadow: none !important;
    }
    html {
        font-size: 80%;
    }
    a {
        color: inherit !important;
        text-decoration: underline !important;
    }
    a.bare,
    a[href^="#"],
    a[href^="mailto:"] {
        text-decoration: none !important;
    }
    a[href^="http:"]:not(.bare)::after,
    a[href^="https:"]:not(.bare)::after {
        content: "(" attr(href) ")";
        display: inline-block;
        font-size: .875em;
        padding-left: .25em;
    }
    abbr[title] {
        border-bottom: 1px dotted;
    }
    abbr[title]::after {
        content: " (" attr(title) ")";
    }
    pre,
    blockquote,
    tr,
    img,
    object,
    svg {
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    svg {
        max-width: 100%;
    }
    p,
    blockquote,
    dt,
    td.content {
        font-size: 1em;
        orphans: 3;
        widows: 3;
    }
    h2,
    h3,
    #toctitle,
    .sidebarblock > .content > .title {
        page-break-after: avoid;
    }
    #header,
    #content,
    #footnotes,
    #footer {
        max-width: none;
    }
    #toc,
    .sidebarblock,
    .exampleblock > .content {
        background: none !important;
    }
    #toc {
        border-bottom: 1px solid #dddddf !important;
        padding-bottom: 0 !important;
    }
    body.book #header {
        text-align: center;
    }
    body.book #header > h1:first-child {
        border: 0 !important;
        margin: 2.5em 0 1em;
    }
    body.book #header .details {
        border: 0 !important;
        display: block;
        padding: 0 !important;
    }
    body.book #header .details span:first-child {
        margin-left: 0 !important;
    }
    body.book #header .details br {
        display: block;
    }
    body.book #header .details br + span::before {
        content: none !important;
    }
    body.book #toc {
        border: 0 !important;
        text-align: left !important;
        padding: 0 !important;
        margin: 0 !important;
    }
    body.book #toc,
    body.book #preamble,
    body.book h1.sect0,
    body.book .sect1 > h2 {
        page-break-before: always;
    }
    .listingblock code[data-lang]::before {
        display: block;
    }
    #footer {
        padding: 0 .9375em;
    }
    .hide-on-print {
        display: none !important;
    }
    .print-only {
        display: block !important;
    }
    .hide-for-print {
        display: none !important;
    }
    .show-for-print {
        display: inherit !important;
    }
}

@media print {
    .asciidoc-box {
        #header > h1:first-child {
            margin-top: 1.25rem;
        }
        .sect1 {
            padding: 0 !important;
        }
        .sect1 + .sect1 {
            border: 0;
        }
        #footer {
            background: none;
        }
        #footer-text {
            color: rgba(0, 0, 0, .6);
            font-size: .9em;
        }
    }
}

.asciidoc-box @media amzn-kf8 {
    #header > h1:first-child {
        margin-top: 1.25rem;
    }
    .sect1 {
        padding: 0 !important;
    }
    .sect1 + .sect1 {
        border: 0;
    }
    #footer {
        background: none;
    }
    #footer-text {
        color: rgba(0, 0, 0, .6);
        font-size: .9em;
    }
}

.asciidoc-box @media amzn-kf8 {
    #header,
    #content,
    #footnotes,
    #footer {
        padding: 0;
    }
}
