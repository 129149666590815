[data-name="subscription-checkout"] {
    border: none;
}

[data-name="subscription-checkout"] .container__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* approximately equal to height of Paddle's iframe */
    min-height: 812px;
    gap: 4px;
}

.paddle-order-details, .paddle-payment-details {
    flex: 1;
    border: var(--plain-border);
    /* Nicely fit to mobile devices and desktop */
    min-width: 350px;
    max-width: 500px;
    border-radius: var(--border-radius);
}

.checkout-row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}

.checkout-row section {
    display: flex;
    flex-direction: column;
}

[data-name="checkout-subscription"] .checkout-row__label,
[data-name="checkout-subscription"] .checkout-row__value,
[data-name="checkout-pay-as-you-go"] .checkout-row__label,
[data-name="checkout-pay-as-you-go"] .checkout-row__value {
    line-height: 2rem;
}

.checkout-list-title {
    background: var(--md-theme-background);
}

li.checkout-list-title span {
    line-height: 2rem;
    padding-left: 1rem;
}

.checkout-row__details {
    font-weight: 300;
    font-size: 0.825rem;
}

[data-name="checkout-totals"] {
    padding-bottom: 2rem;
}