.baseline-overlay {
    fill           : none;
    stroke-opacity : 0;
    stroke-width   : 14px;
    stroke         : rgb(117, 117, 117);
}

.baseline-anchor {
    fill : #2b2b2b;
}

.baseline-anchor.active {
    fill-opacity : 0;
    stroke       : #2b2b2b;
}
