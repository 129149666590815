.not-displayed {
    display: none !important;
}
.form-row {
    margin-bottom: .75em;
}
.form-row--n-elements {
    display: grid;
    /*Can put as many elements you wish, while auto-fit will extend them as much as possible*/
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-auto-flow: row;
    gap: 1em;
}
.form-row__input-w-label {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: end;
}
.form-row__input-w-icon {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 2px;
}
.form-row__label {
    margin-bottom: .7em;
}

.form-row__title {
    padding : 0.25rem 0 1rem;
    color   : var(--theme-text-secondary-on-light);
}

.outlined-button {
    border: 1px solid var(--theme-primary);
    color: var(--theme-primary);
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
}
.outlined-button:active {
    background: var(--theme-primary-light);
}
.link-icon {
    color: inherit;
    opacity: 0.5;
    text-decoration: none;
    width: fit-content;
    display: inline-block;
    transition: 250ms;
}
.link-icon:visited {
    color:inherit;
    text-decoration: none;
}
.link-icon:hover, .link-icon:active {
    color:inherit;
    text-decoration: none;
    opacity: 1;
}
.material-icons--help {
    /*For some reason "help_outlined" icon takes twice in width as in height. Overriding this*/
    width: 1em;
}

.justify-start {
    margin-right : auto;
}

.justify-end {
    margin-left : auto;
}

.visually-hidden {
    display: none !important;
}

.scroll-hidden {
    overflow: hidden !important;
}
