.crawlers-table-header-enabled {
    width : 55px;
}

.crawlers-table-header-progress {
    width : 170px;
}

.crawler-state {
    display : inline-block;
    float   : right;
    height  : 20px;
}

.crawler-state:hover {
    text-decoration : underline;
}

.crawler-state span {
    margin-left    : 8px;
    vertical-align : sub;
}

.crawler-options {
    display : inline-block;
    width   : 60px;
    height  : 20px;
    float   : right;
}

.crawler-options .material-icons {
    display    : inline-block;
    float      : right;
    font-size  : 18px !important;
    opacity    : .25 !important;
    transition : opacity 250ms;
}

.crawler-options .material-icons:hover {
    opacity   : .85 !important;
    transform : scale(1.1);
    cursor    : pointer;
}