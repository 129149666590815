#apply-peak-dialog {
    width  : 420px;
}

.apply-peak-dialog__text {
    display : inline;
    margin-right: .2em;
}

#applyPeakChemFormula {
    font-weight     : bold;
    text-decoration : underline;
}