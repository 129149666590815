dropdown-input {
    display: inline-block;
    width: 100%;
    height: 4rem;
}

dropdown-input dropdown-list {
    display: none;
    margin-top: 3rem;
    /* We use a fixed position because we want to specify the location ourselves (related to the browser window,
    not closest positioned relative)*/
    position: fixed;
    z-index: 9;
}

dropdown-input dropdown-list[open] {
    display: block;
}

dropdown-input.dropdown-select text-input .text-input__input {
    pointer-events: none;
}

dropdown-list {
    width: 100%;
}

dropdown-list ul {
    display: grid;
    position: absolute;
    margin: 0;
    box-shadow: var(--box-shadow);
    background-color: var(--theme-element-background);
    grid-template-columns: 1fr;
    max-height: 10rem;
    padding: 0;
    border-radius: 0.375rem;
    color: var(--theme-text-primary-on-light);
    z-index: 9;
    overflow-y: auto;
    max-width: 100%;
}

dropdown-list li {
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    min-height: 2rem;
    color: var(--theme-text-primary-on-light);
    cursor: pointer;
    background-color: var(--theme-element-background);
}

dropdown-list li[hidden] {
    display: none;
}

dropdown-list li[disabled] {
    pointer-events: none;
    color: var(--mdc-theme-text-disabled-on-background);
}

dropdown-list li:hover, .dropdown-list__item--active {
    background-color: var(--list-background-on-hover-color);
}

dropdown-list li mark {
    font-weight: 600;
    background-color: unset;
}