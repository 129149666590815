:root {
    --chromatogram-card__header-line-height : 20px;
}
.detector-run-card {
    display        : flex;
    flex-direction : column;
    position       : relative;
    height         : 100%;
    border-radius: var(--border-radius);
    background     : var(--theme-element-background);
    box-shadow     : var(--box-shadow);
}

.detector-run-card .progress {
    display          : block !important;
    height           : 0;
    transition       : height 150ms linear 250ms;
}

.detector-run-card--loading {
    pointer-events : none;
}

.detector-run-card--loading svg {
    pointer-events : none;
}

.detector-run-card--loading .progress {
    height : 0.25rem;
}

.detector-run-card--loading .detector-run-card-graph-container {
    filter : opacity(0.5);
}

.detector-run-card:focus {
    outline : none;
}

.detector-run-card-header {
    padding         : 0 8px;
    height          : 35px;
    display         : flex;
    align-items     : end;
    justify-content : space-between;
    color           : var(--card-text-color);
    font-size       : 16px;
    line-height     : var(--chromatogram-card__header-line-height);
}

.chromatogram-header__start {
    display : flex;
    gap: 8px;
}

.chromatogram-header__start .material-icons {
    font-size : var(--chromatogram-card__header-line-height);
}

.chromatogram-header__view-model-label {
    font-size: 12px;
    user-select: none;
}
.chromatogram-header__end {
    display: flex;
    align-items: center;
    gap: 5px;
    line-height: 1rem;
}

.chromatogram-header__end .material-icons {
    font-size  : 16px;
}
.circle {
    width          : 12px;
    height         : 12px;
    margin-right   : 2px;
    border-radius  : 50%;
    vertical-align : middle;
}

.detector-run-card-header .material-icons {
    color      : var(--mdc-theme-text-icon-on-light);
    transition : color 250ms;
}

.detector-run-card-header .material-icons:hover {
    cursor : pointer;
    color  : var(--theme-text-primary-on-light);
    scale  : 1.1;
}

.detector-run-card-graph-container {
    flex-grow  : 2;
    filter     : opacity(1);
    transition : filter 250ms linear 250ms;
}

.chromatogram-header-tour-tip {
    top: -0.25rem;
}