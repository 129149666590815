.delete-batch-notification-dialog__content{
    padding-bottom: 0.5rem;
}

.delete-batch-notification-dialog__radio-buttons-block {
    margin: 1rem 0;
}

.delete-batch-notification-dialog__radio-buttons-row {
    display: flex;
    padding: 0.5rem 0;
}

.delete-batch-notification-dialog__error-block {
    height: 1.5rem;
}

.delete-batch-notification-dialog__error-text {
    color: var(--mdc-theme-error);
    font-size: 0.75rem;
}

.delete-batch-notification-dialog__radio-buttons-row input,
.delete-batch-notification-dialog__radio-buttons-row label {
    cursor: pointer;
}