.add-user-dialog {
    width: 40rem;
    overflow: unset;
}

.add-user-dialog__dropdown-item {
    list-style: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
}

.add-user-dialog__dropdown-item:hover {
    background: var(--list-background-on-hover-color);
    cursor: pointer;
}

.add-user-dialog__dropdown-item {
    display: flex;
}

.add-user-dialog__item-name {
    font-weight: bold;
}

.add-user-dialog__item-description {
    font-size: 0.75rem;
    opacity: 0.8;
}

.add-user-dialog__selected-users {
    max-height: 15rem;
    overflow: auto;
    margin-bottom: 1rem;
}

.add-user-dialog__admin-icon {
    margin-right: 1rem;
    flex-shrink: 0;
}

.add-user-dialog .chips__item:hover {
    cursor: default;
}

.add-user-dialog .chips__item:hover .chips__icon {
    cursor: pointer;
}