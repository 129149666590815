.upload-injection-dialog {
    width: 70vw;
    min-width: 30rem;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
}

.upload-injection-dialog .dialog-buttons-row {
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
}

.upload-injection-dialog .progress {
    display: block !important;
    position: relative;
    margin-top: 1rem;
}

.upload-injection-dialog .dialog-content {
    overflow: auto;
    margin-bottom: 1rem;
}

.upload-injection-dialog .dialog-row:nth-child(1) {
    height: 2.25rem;
}

.upload-injection-dialog .dialog-row:nth-child(2) {
    max-height: 50vh;
    height: 100%;
    overflow: auto;
    padding: 0.5rem 0 0.5rem 0.5rem;
}

.upload-injection-dialog .dialog-row:nth-child(3) {
    height: 1rem;
}

.upload-injection-dialog li span:nth-child(2) {
    padding-left: 1rem;
    white-space: nowrap;
}

.upload-error-message-modal {
    width: 70vw;
    padding: 0;
}

.upload-error-message-modal .dialog-content {
    height: auto;
    max-height: 70vh;
    overflow: auto;
    width: 100%;
    word-wrap: break-word;
}

.upload-injection-dialog__error-list {
    padding-bottom: 1rem;
}

.upload-injection-dialog__error-list li{
    list-style: inside;
}

.upload-injection-dialog__list-item {
    white-space: nowrap;
    padding-bottom: 0.5rem;
}

.upload-injection-dialog__list-item-icon {
    padding-right: 1rem;
}

.upload-injection-dialog__error-prompt {
    padding: .5rem 1rem;
    margin-top: 1rem;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
}