.ion-descriptor-popup {
    position: fixed;
    pointer-events: none;
    background: var(--theme-element-background);
    box-shadow: var(--box-shadow);
    width: 20rem;
    padding: 0.5rem;
    font-size: 0.825rem;
    z-index: 99;
}

.ion-descriptor-popup td:first-of-type {
    padding-right: 0.5rem;
    font-weight: 400;
    width: 9.5rem;
}

.ion-descriptor-popup td:last-of-type {
    font-weight: 600;
}

#spectrum-dialog .scan-card{
    box-shadow: none;
}