.line-chart__trace {
    fill : none;
}

.transient-trace {
    stroke-opacity : 0.75;
}

.transient-trace--active {
    stroke-opacity : 1;
    stroke-width   : 2px;
}

.transient-trace--fadeout {
    stroke-opacity : 0.25;
}