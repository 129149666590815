settings-page {
    flex: 1 1 auto;
    display: flex;
    background: white;
}

.page__content {
    overflow: hidden;
    flex: 1 1 auto;
}

.page__main {
    flex: 1 1 auto;
}

settings-page .page__main--with-sidenav {
    flex: 1 1 auto;
    padding: 4rem 0;
    display: flex;
    width: 100%;
}