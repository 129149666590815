.container[data-name="aliases"] {
    border        : none;
    border-radius : 0;
}

.container[data-name="aliases"] .container__body {
    padding : 1rem 0;
}

.container[data-name="aliases"] .table {
    table-layout                 : fixed;
    --sidenav-width              : 9.375rem;
    --scroll-bar-width           : 26px;
    --table-width                : calc(100vw - var(--sidenav-width) - var(--scroll-bar-width));
    --table-col-1-width          : 7.5rem;
    --table-col-3-width          : 3.975rem;
    --fixed-columns-total-width  : calc(var(--table-col-1-width) + var(--table-col-3-width));
    --middle-columns-total-width : calc(var(--table-width) - var(--fixed-columns-total-width));
    --table-col-2-width          : calc(var(--middle-columns-total-width));
}

.container[data-name="aliases"] .table__cell {
    padding-left  : 0.5rem;
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
}

.container[data-name="aliases"] .table .table__cell:nth-of-type(1) {
    padding-left : 4.9rem;
    width        : var(--table-col-1-width);
}

.container[data-name="aliases"] .table .table__cell:nth-of-type(2) {
    width : var(--table-col-2-width);
}

.container[data-name="aliases"] .table .table__cell:nth-of-type(3) {
    width : var(--table-col-3-width);
}


.container[data-name="aliases"] .table .table__body .table__cell:nth-of-type(3) .material-icons {
    padding-right : 1rem;
}

.container[data-name="aliases"] .table__cell--head {
    font-size   : 0.875rem;
    font-weight : 500;
}

.container[data-name="aliases"] span[data-name="color-box"] {
    display        : inline-block;
    width          : 1rem;
    height         : 1rem;
    border-radius  : 0.25rem;
    vertical-align : middle;
    margin-right   : 0.5rem;
}
