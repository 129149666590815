select-input {
    height: 4rem;
    width: 100%;
    display: inline-block;
}

select-input list-box {
    display: none;
    margin-top: 3rem;
    // We use a fixed position because we want to specify the location ourselves (related to the browser window,
    // not closest positioned relative)
    position: fixed;
    z-index: 9;
}

select-input list-box[open] {
    display: block;
}

select-input text-input {
    cursor: pointer;
}

select-input text-input .text-input__trailing-icon{
    pointer-events: none;
}

select-input[disabled]{
    cursor: default;
}