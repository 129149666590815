.integration-method-card {
    display        : flex;
    flex-direction : column;
    position       : relative;
    height         : 266px;
    width          : 320px;
    border-radius: var(--border-radius);
    background     : var(--mdc-theme-background);
    box-shadow     : var(--box-shadow);
}

.integration-method-card__header {
    width  : 100%;
    height : 48px;
}

.integration-method-card__header .mdc-tab {
    width : 33%;
}

.integration-method-card__tab-content {
    height  : 154px;
    padding : 0 8px;
}

.integration-method-card .dialog-row {
    display : flex;
    height  : auto;
}

.integration-method-card .dialog-row:nth-child(2) {
    flex-direction : column;
}

.integration-method-card .dialog-buttons-row {
    height : 64px;
}

.integration-method-card .dialog-row text-input {
    margin-right : 0.875rem;
    display      : inline-block;
    flex: 1 1 45%;
}

.integration-method-card .dialog-row text-input label {
    font-size : 0.6875rem;
}

.integration-method-card .checkbox-with-label {
    display     : flex;
    align-items : center;
    font-size   : 12px;
    cursor      : pointer;
    user-select : none;
    color       : var(--theme-text-secondary-on-light);
    line-height : 32px;
    width       : fit-content;
}

.integration-method-card .checkbox-with-label input {
    width : 1.3125em;
}

.integration-method-card .js-additional-options-menu {
    margin-top : 88px;
}

.checkbox-with-label {
    display : flex;
}

.integration-method-card__help-icon {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: var(--mdc-theme-text-icon-on-background);
    border: none;
    background: transparent;
}

.integration-method-card__help-icon:hover {
    color: var(--mdc-theme-text-primary-on-background);
}