[popover] {
    position: fixed;
    border: none;
    box-shadow: var(--box-shadow);
    position-anchor: --anchor-el;
    bottom: anchor(bottom);
    justify-self: anchor-center;
    transform: translateX(-50%);
    margin: 0;
}

.popover-button {
    display: contents;
    background: transparent;
    border: none;
    anchor-name: --anchor-el;
}

