.processing-methods-dialog .accordion__item-header-title--detector-family-w-alignment edit-text {
    max-width : 5rem;
    overflow: hidden;
    margin: 0 0.2rem;
}

.accordion__item-header-title--detector-family-name {
    letter-spacing : 0.125rem;
}

.processing-methods-dialog .accordion__item-header-title--detector-family-w-alignment span:nth-child(2) {
    font-size    : 0.8125rem;
    padding-left : 0.25rem;
    display: inline-flex;
}

.processing-methods-dialog .accordion {
    margin-bottom : 1.5rem;
}

.processing-methods-dialog .accordion .accordion__item:last-of-type .list {
    border-bottom-right-radius : var(--border-radius);
    border-bottom-left-radius  : var(--border-radius);
}

.processing-methods-dialog .accordion .list {
    background : var(--list-background-secondary-color);
}