upload-structure-component {
    --border: 1px dashed #afafaf;
    width: 100%;
    height: 100%;
}

.upload-structure-component__body {
    width: 100%;
    height: 100%;
    border: var(--border);
    border-radius: var(--border-radius);
    position: relative;
}

.structure-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.structure-container svg {
    width: 100%;
    height: 100%;
}

.structure-container__hint {
    margin-bottom: -1rem;
}

.structure-container__hint * {
    font-size: .5625rem !important;
}


.upload-structure-component__body span {
    font-size: 1rem;
    color: var(--theme-text-secondary-on-light);
}

.upload-structure-component__body label {
    cursor: pointer;
    color: var(--theme-primary);
}

.drop-structure {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.structure-container .icon--zoom-in {
    position: absolute;
    top: .375rem;
    right: .375rem;
    cursor: pointer;
}

.structure-container .icon--zoom-in {
    transition: color 250ms;
    color: var(--theme-text-hint-on-light);
}

.structure-container .icon--zoom-in:hover {
    color: var(--theme-text-primary-on-light);
}
