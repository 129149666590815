.container {
    display          : flex;
    flex-direction   : column;
    justify-content  : center;
    align-items      : center;
    background-color : var(--mdc-theme-background);
    border           : var(--plain-border);
    border-radius    : var(--border-radius);
}

.container__header {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    padding       : 0 1rem;
}

.container__title {
    font-size : 1.25rem;
}

.container__header-row {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
}

.container__header-title{
    margin: 0;
    font-weight: 400;
}

.container__header-icon {
    display: none;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0.25rem;
    color: var(--mdc-theme-text-icon-on-background);
}

.container__header-icon:hover{
    cursor: pointer;
    color: var(--mdc-theme-text-primary-on-background);
}

/* Should be uncomment when we edit team description  */
/*.container__header:hover .container__header-icon {*/
/*    display: inline-block;*/
/*}*/

.container__header-description {
    padding-bottom: 1rem;
}

.container__header-description:empty {
    display: none;
}

.container__body {
    width : 100%;
    padding: 1rem;
}