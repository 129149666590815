.container[data-name="billing"]{
    min-width: 21rem;
    border: none;
    border-radius: 0;
}

.billing-section {
    margin-bottom: 1rem;
}

.billing-section__header {
    min-height: 3rem;
    padding: 0.5rem 1rem;
    border-bottom: var(--plain-border);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
    font-weight: 600;
    background: #f1f1f1;
}

.billing-section__header h2 {
    font-weight: 500;
    margin: 0;
    order: 0;
    flex: 1 1 auto;
}

.billing-section__main {
    margin-top: 1rem;
    border: var(--plain-border);
    border-radius: var(--border-radius);
}

.billing-section__body {
    padding: 1rem;
}
.billing-section__quotas-row {
    height: 2.5rem;
}
.button--upgrade-plan {
    min-height: 1.8rem;
}


.billing-section__footer {
    padding: 1rem;
    background: #ffebe9;
}

.billing-section__footer:empty {
    display: none;
}

#featuresDisabled:empty {
    display: none;
}

.billing-section__quotas-text {
    display: inline-block;
    width: 7rem;
}

.billing-section__quotas-hint {
    font-style: italic;
    display: inline-block;
    margin-left: 1rem;
}

.billing-section__description {
    font-style: italic;
    padding-top: 1rem;
}

#billing-section-usage .container {
    border: none;
}

.cancel-subscription-confirmation-dialog__body {
    padding: 1rem 2rem 2rem 0;
}

.cancel-subscription-confirmation-dialog__body p {
    padding: 0.3rem 0;
}