.page__sidenav--left {
    flex-shrink      : 0;
    width            : 10rem;
    background-color : var(--mdc-theme-background);
    border-right     : 1px #eee solid;
    display          : flex;
    flex-direction   : column;
    padding-top      : 1rem;
}

.sidenav__link {
    display         : flex;
    align-items     : center;
    font-size       : 0.9375rem;
    line-height     : 3.25rem;
    padding         : 0 1rem;
    color           : var(--theme-text-primary-on-light);
    text-decoration : none;
    cursor          : pointer;
}

.sidenav__link:hover {
    background-color : rgba(48, 48, 48, 0.06);
}

.sidenav__link--active {
    box-shadow       : inset 4px 0 0 var(--theme-primary);
    color            : var(--theme-primary);
    background-color : #f9f9f9;
}