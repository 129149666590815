.page-header {
    height: 4rem;
    top: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 4;
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    min-width: 350px;
}
.page-header--borderless {
    height: 4rem;
    border-bottom: none;
}

.page-header.scroll {
    -webkit-box-shadow: var(--page-header-box-shadow);
    box-shadow: var(--page-header-box-shadow);
}

.page-header__row {
    display: flex;
    top: 0;
    position: static;
    background-color: var(--theme-element-background);
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.page-header__row a {
    text-decoration: none;
}

.page-header__title {
    color: var(--theme-text-primary-on-light);
    line-height: var(--page-header-title-line-height);
    vertical-align: super;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    text-decoration: inherit;
    text-transform: inherit;
    padding-left: 20px;
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.page-header__section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1 auto;
    min-width: 0;
    padding: 8px 12px;
    z-index: 1;
}

.page-header__section--align-mid {
    display: flex;
    flex-grow: 3;
    flex-shrink: 1;
    justify-content: end;
    max-width: 600px;
    min-width: 150px;
}
.page-header__section--align-mid:empty{
    display: none;
}
.page-header__section--align-end {
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: end;
    padding: 0;
}

.page-header__section--align-mid .mdc-button {
    margin-left: 8px;
}

.page-header__section--align-end i, .page-header__section--align-end > svg, .top-app-bar-nav-icon {
    cursor: pointer;
    color: black;
    opacity: .5;
    transition: 250ms;
    padding-left: 8px;
}

.header__right{
    display: flex;
}

.page-header__section--align-end i:hover, .page-header__section--align-end > svg:hover, .top-app-bar-nav-icon:hover {
    opacity: .9;
}

.page-header__section--align-end .add-visualization, .page-header__section--align-end > button {
    margin: 0 0.5rem;
}

.page-header__section--user {
    justify-content: end;
    flex: none;
    gap: 0.5rem;
    margin: 0 1rem;
}

.user-section__button-name {
    cursor: pointer;
    border: 1px solid;
    box-shadow: var(--box-shadow);
}

.user-section__button-name:hover {
    opacity: 0.7;
    box-shadow: var(--box-shadow-hover);
}
.header-buttons-section {
    display: contents;
}

.header-chips {
    padding: 0 1rem;
}

.header-chip {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 7px;
    padding-right: 7px;
    white-space: nowrap;
    font-size: 0.8125rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    border-radius: 16px;
    cursor: unset;
    outline: 0;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid;
}
.header-chip--warning {
    border-color: #ffda47;
    background-color: #fffbeb;
    color: #8a5300;
}
.header-chip--error {
    border-color: #febdc3;
    background-color: #fff0f1;
    color: #92010e;
}

.page-header__breadcrumbs-section {
    flex-shrink: 1;
    display: flex;
    width: calc(100% - 40px); /* 40px - logo width */
}

.breadcrumbs {
    display: flex;
    margin-left: 1rem;
    align-items: center;
    width: 100%;
    min-width: 2rem;
}

.breadcrumbs__item {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 2 auto;
    min-width: 1.75rem;
}

.breadcrumbs__item:last-child[data-id="breadcrumbs-org"] {
    max-width: 100%;
}

.breadcrumbs__item:not(:last-child) .breadcrumbs__link {
    color: var(--theme-text-hint-on-light );
    margin-right: 1rem;
}

.breadcrumbs__item:last-child .breadcrumbs__link {
    pointer-events: none;
    color: var(--theme-text-primary-on-light);
}

.breadcrumbs__item:not(:last-child)::after {
    content: "/";
    position: absolute;
    right: 0.3rem;
    color: var(--theme-text-hint-on-light );
}

.breadcrumbs__link{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.breadcrumbs__link:hover {
    text-decoration: underline;
    color: var(--theme-text-secondary-on-light);
}

.breadcrumbs__menu-icon {
    height: 1.5rem;
    cursor: pointer;
    display: inline-block;
}

.breadcrumbs__item:not(:last-child) .breadcrumbs__menu-icon {
    display: none;
}

@media (max-width : 592px){
    .breadcrumbs__item:not(:last-child) .breadcrumbs__link {
        max-width: 70px;
    }
}