.switch {
    /*Size of the all children elements based on the size of the switch, this allows the switch to be resized*/
    font-size                     : 1rem;
    display                       : flex;
    padding                       : 0.375em;
    cursor                        : pointer;
    --background-color-transition : background-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
    --slider-position-transition  : margin-left 0.2s ease-in-out;
}

.switch[disabled] {
    /*
    We want to show "not-allowed" cursor on disabled switch, but we still allow all pointer events to pass through it.
    To prevent the switch from being clicked, you have to set property "disabled" on the input element itself.
    */
    cursor  : not-allowed;
    opacity : 0.4;
}

.switch__input {
    display : none;
}

.switch__slider {
    display          : flex;
    width            : 2em;
    height           : 0.625em;
    border-radius    : 0.3125em;
    align-items      : center;
    transition       : var(--background-color-transition);
    background-color : var(--mdc-theme-text-icon-on-background);

}

.switch__slider::after {
    display          : flex;
    background-color : var(--mdc-theme-background);
    box-shadow       : var(--box-shadow);
    width            : 1em;
    height           : 1em;
    border-radius    : 50%;
    pointer-events   : none;
    z-index          : 1;
    margin-left      : 0;
    transition       : var(--slider-position-transition), var(--background-color-transition);
    content          : "";
}

.switch__input:checked + .switch__slider {
    background-color : var(--theme-secondary-light);
}

.switch__input:checked + .switch__slider::after {
    background-color : var(--mdc-theme-secondary);
    margin-left      : 1em;
}
