.tour-tip {
    /*--tour-tip__main-color: rgb(51, 51, 51);*/
    --tour-tip__main-color: rgb(102, 124, 255);
    --tour-tip__text-color: white;
    position: relative;
    display: inline-block;
    font-size: 1rem;
}

.tour-tip__content {
    width: max-content;
    background-color: var(--tour-tip__main-color);
    color: var(--tour-tip__text-color);
    text-align: center;
    border-radius: 6px;
    padding: 0.75rem;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tour-tip__content::after {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
}

.tour-tip__arrow-bottom {
    bottom: 150%;
    left: 50%;
    transform: translateX(-77%);
}

.tour-tip__arrow-bottom:after {
    top: 100%;
    left: 85%;
    border-color: var(--tour-tip__main-color) transparent transparent transparent;
}

.tour-tip__arrow-top{
    top: 100%;
    left: 50%;
    transform: translateX(-77%) translateY(25%);
}

.tour-tip__arrow-top:after {
    bottom: 100%;
    left: 75%;
    border-color: transparent transparent var(--tour-tip__main-color) transparent;
}

.tour-tip__arrow-right {
    top: -5px;
    right: 110%;
    transform: translateY(25%);
}

.tour-tip__arrow-right:after {
    top: 25%;
    left: 100%;
    border-color: transparent transparent transparent var(--tour-tip__main-color);
}

.tour-tip__arrow-left {
    left: 5px;
    transform: translateY(-25%);
}

.tour-tip__arrow-left:after {
    top: 25%;
    right: 100%;
    border-color: transparent var(--tour-tip__main-color) transparent transparent;
}

.tour-tip .tour-tip__content {
    visibility: visible;
}

.tour-tip__row {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: start;
}

.tour-tip__footer{
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.tour-tip__text{
    max-width: 200px;
    text-align: start;
}

.tour-tip__reject {
    font-size: 0.75rem;
    cursor: pointer;
}

.tour-tip .tour-tip__icon {
    font-size: 1rem;
    cursor: pointer;
    color: var(--tour-tip__text-color) !important;
    scale: 1 !important;
}