.home-page {
    width: 100%;
    flex: 1 1 auto;
    box-sizing: border-box;
    min-width: 350px;
    padding: 4rem 0;
    background: white;
}

.home-page__container {
    padding: 1rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
}

.home-page__main-column {
    display: flex;
    min-height: 50vh;
    justify-content: center;
    flex: 1 1 70%;
    padding: 0 0.75rem;
    overflow: hidden;
    width: 100%;
}

.home-page__main-column-body {
    max-width: 75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6rem;
    width: 100%;
}

.home-page__intro-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 75rem;
}

.home-page__title {
    text-align: center;
    font-weight: normal;
    font-size: 56px;
    color: #4b4949;
    margin-top: 10rem;
    margin-bottom: 1rem;
}

.home-page__subtitle {
    text-align: center;
    font-size: 1.25rem;
    font-weight: normal;
    letter-spacing: 0.1rem;
}

.realistic-marker-highlight{
    position:relative;
}

.highlight-container, .highlight {
    position: relative;
}

.highlight-container  {
    display: inline-block;
}

.highlight-container:before{
    content: " ";
    display: block;
    height: 90%;
    width: 100%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    transform: rotate(2deg);
    top: 3px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 10px 3px 3px 10px;
}

.highlight-container--yellow::before {
    background: #ffd500;
}

.highlight-container--green::before {
    background: #99f6a7;
}

.highlight-container--magenta::before {
    background-color:#ff6db7;
}

.home-page__action-cards {
    display: flex;
    flex-wrap: wrap;
    max-width: 75rem;
    width: 100%;
    gap: 2rem;
}

.home-page__card {
    padding: 1rem 0;
}

.home-page__card--action {
    flex: 1 1 45%;
    min-width: 18rem;
}

.home-page__card--news {
    width: 100%;
    max-width: 75rem;
}

.home-page__card-body, .news-item__body {
    padding: 2rem 1rem;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    border: 1px solid var(--border-color );
}

.home-page__card-button{
    margin-bottom: 1rem;
    font-weight: bold;
    border-radius: 3rem;
    width: 15rem;
    height: 3rem;
    font-size: 1rem;
    background: white;
    color: var(--theme-primary);
    white-space: nowrap;
}

.home-page__card-description {
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: center;
}

.home-page__card-title, .news-item__title {
    font-size: 1.75rem;
    color: #4b4949;
    text-align: center;
}

.home-page__news-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.news-item__body {
    padding: 1rem;
    align-items: start;
    max-width: 75rem;
    background: var(--list-background-secondary-color);
}

.news-item {
    padding: 1rem 0;
}

.news-item__title {
    font-size: 1.75rem;
    color: #4b4949;
    margin-bottom: 0.83rem;
    font-weight: bold;
    text-align: start;
}

.news-item__ul {
    list-style: unset;
    padding-left: 2rem;
}

.home-page__your-menu-section {
    padding: 1rem;
    border-right: 1px solid var(--border-color);
    min-height: 100%;
    flex: 1 1 20%;
    overflow: hidden;
    max-width: 25rem;
    min-width: 13rem;
}

.home-page__your-menu-title {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    padding-bottom: 0.75rem;
    color: #4b4949;
    font-weight: bold;
}

.home-page__org-block-title-icon {
    font-size: 1.25rem;
    line-height: 1.25rem;
    padding: 0.25rem;
    color: var(--mdc-theme-text-icon-on-background);
    margin-left: 0.25rem;
}

.home-page__org-block-title-icon:hover {
    cursor: pointer;
    color: var(--mdc-theme-text-primary-on-background);
}

.home-page__org-row {
    display: flex;
    align-items: center;
    height: 3rem;
}

.home-page__org-name {
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
    color: var(--theme-primary);
    text-decoration: underline;
}

.home-page__org-link {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    gap: 1rem;
    text-decoration: none;
}

.home-page__org-link:hover {
    background-color: var(--list-background-on-hover-color);
}

.hero {
    max-width: 50rem;
    width: 100%;
}

@media (max-width: 850px){
    .home-page__your-menu-section {
        border: none;
        flex: 1 1 100%;
    }
}