.slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.slides {
    width: 400%;
    height: 100%;
    display: flex;
}

.slides__item {
    width: 25%;
    transition: all 0.6s ease;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.slides__image {
    max-width: 100%;
    max-height: 78%;
    aspect-ratio: auto;
    display: block;
    margin: 0 auto;

    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);
}
