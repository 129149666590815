.drag-n-drop-area {
    width            : 100%;
    height           : 100%;
    position         : absolute;
    display          : flex;
    top              : 0;
    flex-direction   : column;
    justify-content  : center;
    align-items      : center;
    box-sizing       : border-box;
    background-color : #f9f9f9;
    border           : 1px dashed gray;
    border-radius: var(--border-radius);
}

.drag-n-drop-area span {
    font-size : 23px;
}