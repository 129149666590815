.toggle-zoom-icon {
    font-size   : 20px;
    color       : var(--mdc-theme-text-icon-on-light);
    cursor      : pointer;
    transition  : color 250ms;
    user-select : none;
}

.toggle-zoom-icon:hover {
    color  : var(--theme-text-primary-on-light);
}
