.forbidden-injections-dialog .dialog__header {
    font-size: 1rem;
}

.forbidden-injections-dialog__batch-block{
    margin: 1rem 0;
}

.forbidden-injections-dialog__batch-name{
    margin: 0.5rem 0;
}

.forbidden-injections-dialog__content {
    overflow: auto;
    margin: 2rem 0;
    padding: 0 1rem 0 0;
}