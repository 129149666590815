injection-page {
    display: block;
    margin-top: 4rem;
    width: 100%;
    padding-bottom: 4rem;
    flex: 1 1 auto;
}

.injection-page__chart-col {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.injection-page__chart-col--left {
    width      : 70%;
    box-sizing : border-box;
    min-width  : 350px;
}

.injection-page__chart-col--right {
    width       : calc(30% - 4px);
    box-sizing  : border-box;
    min-width   : 296px;
}

.injection-chart-slot {
    height: 266px;
}
