.container[data-name="org-members-list"] {
    min-width: 21rem;
    border: none;
    border-radius: 0;
}

.container[data-name="org-members-list"] .container__body {
    padding: 1rem 0;
}

.container[data-name="org-members-list"] .table__cell:nth-of-type(1) {
    padding-left: 1.5rem;
}

.org-members-list-table{
    table-layout: fixed;
}

.org-members-list__user-icon {
    margin-right: 0.5rem;
}

.org-members-list__user-full-name {
    padding-right: 0.75rem;
}

.org-members-list__user-full-name:empty {
    display: none;
}

.org-members-list__username {
    font-size: 0.9rem;
}

.table__cell--org-members-list-action {
    width: 4rem;
}

.table__cell--org-members-list-teams-list {
    padding-left: 0.5rem;
}

.org-members-list__teams-list-link{
    color: var(--theme-primary);
    text-decoration: underline;
    cursor: pointer;
}

.org-members-list__delete-action {
    display: none;
}

.table__row-member:hover .org-members-list__delete-action{
    display: inline-block;
}