.page-table__body {
    padding: 0;
    margin: 0;
}
.page-table__row {
    height: 48px;
    border-bottom: 1px #eee solid;
    font-weight: 300;
    padding: 0 0.5rem 0 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.page-table__row:hover {
    background: var(--md-theme-background);
}
.page-table__row--header {
    display: flex;
    justify-content: flex-start;
}
.page-table__header-cell {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    font-size: 0.8em;
    font-weight: 500;
}
.page-table__row > * {
    flex: 1 1 0;
}

/*
 1st column is usually the most important (unless there's a column with checkboxes in front of it),
 so making it largest by default.
 */
.page-table__row > :first-child {
    margin: 0 0 0 55px;
    flex: 2 1 0;
}

/************
  Modification for particular tables (e.g. a table of batches, injections, tc) in case defaults didn't work
************/
.page-table__row--edit-batch-injections > :first-child {
    flex: 0 0 80px;
}

.page-table__cell {
    overflow           : hidden;
    /*max-height = 2 x line-height in order to allow only 2 rows per cell*/
    line-height        : 1.2em;
    max-height         : 2.4em;
    display            : -webkit-box;
    -webkit-line-clamp : 2;
    -webkit-box-orient : vertical;
}

.injection-list__row {
    position: relative;
}

.list__checkbox-input {
    position: absolute;
    left: 0;
    width: 1rem;
    display: none;
    margin: 1rem 1.25rem;
}

.page-table__row:hover .list__checkbox-input {
    display: inline-block;
}

.list__checkbox-input:checked {
    display: inline-block;
}

.list__header {
    text-align: center;
    padding: 2rem;
    line-height: 2;
}
