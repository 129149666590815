.integration-method-editor__original-signal-trace{
    stroke       : rgba(0, 0, 0, .5);
    stroke-width : 1px;
}
.integration-method-editor__smoothed-signal-trace{
    stroke       : rgba(0, 0, 0, .5);
    stroke-width : 1px;
}
.integration-method-editor__deriv1-trace{
    stroke       : rgba(0, 128, 0, 0.5);
    stroke-width : 1px;
}
.integration-method-editor__deriv2-trace{
    stroke       : rgba(0, 0, 255, 0.5);
    stroke-width : 1px;
}
.integration-method-editor__curvature-threshold-line {
    stroke       : rgba(0, 0, 255);
    stroke-width : 2px;
    stroke-dasharray: 3;
}

.integration-method-editor__detected-peak {
    fill: #cafebabe;
    stroke: gray;
    fill-opacity: .15;
}