.button {
	min-width       : 3rem;
	min-height      : 2.25rem;
	font-size       : 0.875rem;
	line-height     : 1;
	border-radius   : 0.25rem;
	letter-spacing  : 0.09em;
	font-weight     : 500;
	display         : inline-flex;
	align-items     : center;
	justify-content : center;
	cursor          : pointer;
	padding         : 0 1rem;
}

.button[disabled] {
	cursor: default;
	color          : var(--mdc-theme-text-disabled-on-background);
}

.button--contained {
	background : var(--theme-primary);
	color      : var(--mdc-theme-text-primary-on-dark);
	border     : 1px solid var(--theme-primary);
	box-shadow : var(--box-shadow);
	transition : box-shadow cubic-bezier(0.4, 0, 0.2, 1);
}

.button--contained[disabled] {
	background     : var(--background-button-disabled);
	box-shadow     : none;
	border         : 1px solid transparent;
}

.button--contained:hover {
	opacity    : 0.9;
	box-shadow : var(--box-shadow-hover);
}

.button--contained[disabled]:hover {
	opacity    : unset;
	box-shadow : unset;
}

.button--contained:active {
	opacity : 0.8;
}

.button--outlined {
	background : none;
	color      : var(--theme-primary);
	border     : 1px solid var(--theme-primary);
}

.button--outlined[disabled] {
	border : 1px solid var(--mdc-theme-text-disabled-on-background);
}

.button--outlined:hover {
	background : var(--background-button-text-hover);
}

.button--outlined[disabled]:hover {
	background : unset;
}

.button--outlined:active {
	background : var(--background-button-text-active);
}

.button--text {
	border     : none;
	background : none;
	color      : var(--theme-primary);
	/* If you need underlined text in a button, then use '.button--link' class */
	text-decoration: none;
}

.button--text:hover {
	background : var(--background-button-text-hover);
}

.button--text[disabled]:hover {
	background : unset;
}

.button--text:active {
	background : var(--background-button-text-active);
}

.button--text-red {
	color      : var(--mdc-theme-error);
}

.button-group {
	display       : inline-flex;
	box-shadow    : var(--box-shadow);
	border-radius : 0.25rem;
}

.button-group[disabled] {
	pointer-events : none;
	box-shadow     : none;
}

.button-group__button {
	min-width       : 3rem;
	min-height      : 2.25rem;
	font-size       : 0.875rem;
	line-height     : 1;
	border-radius   : 0.25rem;
	letter-spacing  : 0.09em;
	font-weight     : 500;
	display         : inline-flex;
	align-items     : center;
	justify-content : center;
	cursor          : pointer;
	padding         : 0 1rem;
	background      : var(--theme-primary);
	color           : var(--mdc-theme-text-primary-on-dark);
	border          : 1px solid var(--theme-primary);
	transition      : box-shadow cubic-bezier(0.4, 0, 0.2, 1);
}

.button-group__button:hover {
	opacity    : 0.9;
	box-shadow : var(--box-shadow-hover);
}

.button-group__button:active {
	opacity : 0.8;
}

.button-group[disabled] .button-group__button {
	color      : var(--mdc-theme-text-disabled-on-background);
	background : var(--background-button-disabled);
	border     : 1px solid transparent;
}

.button-group[disabled] .button-icon {
	fill : var(--mdc-theme-text-disabled-on-background);
}

.button-group__button--left {
	border-radius : 0.25rem 0 0 0.25rem;
}

.button-group__button--right {
	border-radius : 0 0.25rem 0.25rem 0;
	min-width     : 0;
	padding       : 0;
}

.button-icon {
	fill   : var(--mdc-theme-text-primary-on-dark);
	width  : 1.5rem;
	height : 1.5rem;
}

.button--floating {
	position: fixed;
	top: 90%;
	left: 90%;
	height: 56px;
	width: 56px;
	border-radius: 50%;
}

.button--link {
	border: none;
	background: unset;
	font-size: inherit;
	padding: 0;
	color: var(--theme-primary);
	text-decoration: underline;
	cursor: pointer;
}