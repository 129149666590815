/*to be moved out into a separate file or removed if we don't need to customize it*/
.crawler-info-dialog {
    width: 450px;
    height: 200px;
}

#crawler-dialog {
    width      : 450px;
    transition : height 250ms;
}

.change-passwd-button {
    height: 2.75em;
    align-self: center;
}

#safari-prevent-autocomplete {
    position : absolute;
    height   : 0;
    overflow : hidden;
}
