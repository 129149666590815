.icon-with-dropdown-menu{
    position: relative;
}
.icon-with-dropdown-menu__icon{
    cursor: pointer;
}
.icon-with-dropdown-menu__menu-below {
    display: grid;
    position: absolute;
    right: 0;
    margin: 0;
    box-shadow: var(--box-shadow);
    background-color: var(--theme-element-background);
    grid-template-columns: 1fr;
    width: max-content;
    padding: 0;
    border-radius: 6px;
    color: var(--theme-text-primary-on-light);
    top: 2em;
}
.icon-with-dropdown-menu__menu-below[data-hidden] {
    display: none;
}
.icon-with-dropdown-menu__menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    min-height: 42px;
    cursor: pointer;
}
.icon-with-dropdown-menu__menu-item:hover:not([disabled]) {
    background-color: var(--list-background-on-hover-color);
}
.icon-with-dropdown-menu__menu-item[disabled]{
    cursor: default;
    color: var(--theme-text-hint-on-light);
}
.icon-with-dropdown-menu__menu-item[id="injection-delete"],
.icon-with-dropdown-menu__menu-item[id="batch-delete"],
.icon-with-dropdown-menu__menu-item[id="post-delete"] {
    color: red;
}