.non-integrable {
    pointer-events : none;
}

.non-integrable__area {
    fill    : grey;
    opacity : .3;
    mask    : url(#mask-stripe);
}

.non-integrable__border {
    fill    : grey;
    opacity : .3;
}
.non-integrable__text {
    transform      : matrix(1, 0, 0, -1, 0, 0) rotate(-90deg);
    fill           : #9e9e9e;
    font-size      : 12px;
    pointer-events : none;
}

.non-integrable__area--editable {
    fill : #ff1b1b;
}

.non-integrable__border--editable {
    fill : #ff1b1b;
    cursor  : ew-resize;
    pointer-events: all;
}