.menu-caller {
    position: relative;
}

.menu {
    display: grid;
    position: absolute;
    right: 0;
    margin: 0;

    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    background-color: var(--theme-element-background);
    grid-template-columns: 1fr;
    width: max-content;
    padding: 0;
    border-radius: 6px;
    color: var(--theme-text-primary-on-light);
    z-index: 9;
}
.menu--below {
    top: 2em;
}
.menu--left {
    right: 1em;
}
.menu[data-hidden] {
    display: none;
}
.menu__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    min-height: 42px;
    cursor: pointer;
}
.menu__item--disabled {
    /*Can't do
        pointer-events: none;
    because this would prevent tooltips from being shown on hover. So need to do this with JS*/
    color: var(--mdc-theme-text-disabled-on-background);
}
.menu__hotkey {
    color: var(--theme-text-hint-on-light);
    margin-left: 1em;
    margin-bottom: .1em;
}
.menu__item:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.menu__separator {
    height: 1px;
    width: 100%;
    border: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.1);
}