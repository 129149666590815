.axis-horizontal-container {
    position: relative;
}

.axis-o {
    display: none;
}

.axis-o:not(:empty) {
    display: unset;
    position: absolute;
    top: 0;
    left: 0;
}

.axis-x:has(+ .axis-o:not(:empty)) {
    display: none;
}

.axis-x:has(+ .axis-o:empty) {
    display: unset;
}