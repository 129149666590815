.add-injection-dialog {
    width: 40rem;
    overflow: unset;
}

.add-injection-dialog__content {
    position: relative;
}

.add-injection-dialog__row {
    padding-bottom: .8rem ;
}

.add-injection-dialog__row-subtitle{
    font-style: italic;
    font-size: .8rem;
    color: var(--theme-text-hint-on-light);
}

.add-injection-dialog__drop-area {
    width: 100%;
    height: 15rem;
    border: 1px dashed var(--border-color);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #f9f9f9;
}

.add-injection-dialog__upload-icon path {
    fill: var(--theme-text-hint-on-light);
}

.add-injection-dialog__drop-area-description {
    color: var(--theme-text-hint-on-light);
}

.add-injection-dialog__drop-area .drag-n-drop-area {
    box-sizing: content-box;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: var(--theme-element-background);
}

.add-injection-dialog__drop-area .drag-n-drop-area span {
    background: var(--theme-element-background);
    font-size: 1rem;
}

.add-injection-dialog__link {
    color: var(--theme-primary);
    text-decoration: underline;
    cursor: pointer;
}

.add-injection-dialog__help-icon {
    position: absolute;
    top: 1.3rem;
    right: 1.3rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: var(--mdc-theme-text-icon-on-background);
    border: none;
    background: transparent;
}

.add-injection-dialog__help-icon:hover {
    cursor: pointer;
    color: var(--mdc-theme-text-primary-on-background);
}

.add-injection-dialog__help-block {
    position: absolute;
    top: 2.75rem;
    right: 1.3rem;
    width: fit-content;
    height: 2.5rem;
    border-radius: 6px;
    box-shadow: var(--box-shadow);
}

.add-injection-dialog__help-block:hover {
    box-shadow: var(--box-shadow-hover);
}

.add-injection-dialog__help-link, .add-injection-dialog__help-link:visited {
    padding: 0.75rem 1rem;
    line-height: 2.5rem;
    color: var(--theme-primary);
}