.chips {
    --chip-color-primary : 6, 95, 212;
    --chip-height        : 24px;
    width                : 100%;
    display              : flex;
    gap                  : 2px;
    overflow             : hidden;
}

.chip-container {
    display     : flex;
    position    : relative;
    align-items : center;
    height      : var(--chip-height);
    box-sizing  : border-box;
    flex-grow   : 1;
}

.chips__item {
    display       : flex;
    align-items   : center;
    padding       : 0 8px;
    border-radius : 8px;
    min-width     : 45px;
    height        : var(--chip-height);
    box-sizing    : border-box;
    position      : absolute;
    left          : 0;
    font-size     : 0.875rem;
    font-weight   : 400;
    color         : black;
    border        : solid 1px rgba(var(--chip-color-primary));
    cursor        : pointer;
    user-select   : none;
    background    : white;
    width         : max-content;
}

.chips__item:hover {
    box-shadow : inset 0 var(--chip-height) rgba(var(--chip-color-primary), .04);
    z-index    : 1;
}

.chips__item:active {
    box-shadow : inset 0 var(--chip-height) rgba(var(--chip-color-primary), .08);
}

.chips__item--selected {
    border-color: var(--theme-primary-light);
    color: var(--theme-primary);
}

.chips__icon {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-left: 0.25rem;
    color: var(--mdc-theme-text-icon-on-background);
}

.chips__icon:hover {
    color: var(--mdc-theme-text-primary-on-background);
}

.chips--wrapped {
    flex-wrap: wrap;
}

.chips--wrapped .chip-container {
    /** We should remove option 'position: relative' because in wrapped mode want to apply default position */
    position: unset;
}

.chips--wrapped .chips__item {
    /** We should remove option 'position: absolut' because in wrapped mode want to apply default position */
    position: unset;
}