.page__header {
    display          : flex;
    position         : fixed;
    top              : 0;
    left             : 0;
    right            : 0;
    justify-content  : space-between;
    align-items      : center;
    height           : 4rem;
    padding          : 0.5rem 0 0.5rem 0.75rem;
    color            : var(--theme-text-primary-on-light);
    background-color : var(--mdc-theme-background);
    z-index          : 9;
    border-bottom: var(--plain-border);
}

.page__header.scroll {
    -webkit-box-shadow: var(--page-header-box-shadow);
    box-shadow: var(--page-header-box-shadow);
}

.page__header .header__title {
    font-weight    : 500;
    letter-spacing : 0.009375em;
    padding        : 0 1rem;
}
