.container[data-name="members"] {
    min-width: 21rem;
    border: none;
    border-radius: 0;
}

.container[data-name="members"] .container__body {
    padding: 1rem 0;
}

.container[data-name="members"] .table__cell:nth-of-type(1) {
    padding-left: 1.5rem;
}

.member-table {
    table-layout: fixed
}

.table__cell--admin-action {
    width: 4rem;
}

.member-table__admin-icon {
    margin-right: 0.5rem;
}

.member-table__delete-action{
    visibility: hidden;
}

.table__row-admin:hover .member-table__delete-action {
    visibility: visible;
    cursor: pointer;
}

.member-table__undo-link {
    color: var(--theme-primary);
    text-decoration: underline;
    cursor: pointer;
}

.member-table__deleted-info {
    display: none;
}

.table__row-admin--deleted .member-table__undo-action-block {
    color: var(--theme-text-secondary-on-light);
    font-style: italic;
    text-align: end;
    padding-right: 1rem;
}

.table__row-admin--deleted .table__cell--admin-name {
    opacity: 0.4;
}

.member-table .table__cell--admin-action {
    width: 7rem;
    margin-right: 1rem;
}

.member-table .action-icons {
    justify-content: end;
}

.member-table__admin-full-name {
    padding-right: 0.75rem;
}

.member-table__admin-full-name:empty {
    display: none;
}

.member-table__admin-username {
    font-size: 0.9rem;
}

.table__cell--admin-name {
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}

.table__no-member-row {
    padding: 1rem;
}
