.create-organization-dialog {
    width: 35rem;
}

dialog.create-organization-dialog .dialog-content {
    padding: .25rem .75rem .25rem .75rem;
    border-width: 1.25rem 0 0 0;
    border-color: var(--theme-element-background);
    border-style: solid;
    overflow: auto;
    max-height: 80vh;
}

dialog.create-organization-dialog .dialog-buttons {
    height: 2.25rem;
}

.create-organization-dialog__prompt-row {
    height: 3rem;
}

.create-organization-dialog__prompt {
    font-size: 0.75rem;
    font-style: italic;
    color: var(--theme-text-hint-on-light);
    height: 3rem;
}

.create-organization-dialog__url {
    color: var(--theme-text-primary-on-light);
}