.user-team-list-dialog {
    width: 35rem;
}

.user-team-list-dialog__content {
    padding-bottom: 1rem;
}

.user-team-list-dialog__user-name{
    margin-bottom: 1rem;
    overflow: hidden;
}

.user-team-list-dialog__team-row {
    display: flex;
    padding: 0.5rem 0;
}

.user-team-list-dialog__checkbox {
    width: 1rem;
    margin-right: 1rem;
}

.user-team-list-dialog__checkbox:focus-within {
    outline: 1px solid var(--theme-text-hint-on-light);
}

.user-team-list-dialog__prompt-block {
    height: 1.5rem;
    margin-top: 1rem;
    color: var(--mdc-theme-error);
}