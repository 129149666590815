.chromatogram-graph__peak-labels {
    position       : absolute;
    overflow       : visible;
    /**
    The layer with labels is above the layer with peak-editing controls. And we can't move it underneath because
    in that case the line between peak and label will be underneath too and won't be visible. Neither we could
    put this layer as a sub-layer within the chromatogram graphs, because this one should be able to overflow.
     */
    pointer-events : none;
}

.peak-labels__label {
    cursor         : pointer;
    /**
    We set "pointer-events: none" to the parent, but the label itself must be clickable.
     */
    pointer-events : all;
}

.peak-labels__svg {
    overflow : hidden;
}

.peak-labels__label-text {
    pointer-events : none;
    font-size      : 12px;
}

.peak-labels__label-rect {
    filter : url(#drop-shadow);
    fill   : white;
}

.peak-labels__link {
    fill             : none;
    pointer-events   : none;
    stroke           : black;
    stroke-dasharray : 2, 4;
}
