.spectrum-popup .spectrum-component__header {
    height: 20px;
}

.spectrum-popup .spectrum-component__body {
    width: 650px;
    height: 350px;
    box-shadow: none;
}

.spectrum-popup .dancing-bars {
    position: absolute;
    top: 200px;
    left: 340px;
    margin: 0 auto;
    z-index: 9;
}
