.container[data-name="crawlers"] {
    border        : none;
    border-radius : 0;
}

.container[data-name="crawlers"] .container__body {
    padding : 1rem 0;
}

.container[data-name="crawlers"] .table {
    table-layout                 : fixed;
    --sidenav-width              : 9.375rem;
    --scroll-bar-width           : 26px;
    --table-width                : calc(100vw - var(--sidenav-width) - var(--scroll-bar-width));
    --table-col-1-width          : 4.5rem;
    --table-col-5-width          : 3.975rem;
    --fixed-columns-total-width  : calc(var(--table-col-1-width) + var(--table-col-5-width));
    --middle-columns-total-width : calc(var(--table-width) - var(--fixed-columns-total-width));
    --table-col-2-width          : calc(var(--middle-columns-total-width) * 0.5);
    --table-col-3-width          : calc(var(--middle-columns-total-width) * 0.3);
    --table-col-4-width          : calc(var(--middle-columns-total-width) * 0.2);
}

.container[data-name="crawlers"] .table__cell {
    padding-left  : 0.5rem;
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
}

.container[data-name="crawlers"] .table .table__cell:nth-of-type(1) {
    padding-left : 1.5rem;
    width        : var(--table-col-1-width);
}

.container[data-name="crawlers"] .table .table__cell:nth-of-type(2) {
    width : var(--table-col-2-width);
}

.container[data-name="crawlers"] .table .table__cell:nth-of-type(3) {
    width : var(--table-col-3-width);
}

.container[data-name="crawlers"] .table .table__cell:nth-of-type(4) {
    width : var(--table-col-4-width);
}

.container[data-name="crawlers"] .table .table__cell:nth-of-type(5) {
    width : var(--table-col-5-width);
}

.container[data-name="crawlers"] .table .table__body .table__cell:nth-of-type(4) span {
    font-size      : 1rem;
    line-height    : 1.5rem;
    vertical-align : middle;
    display        : inline-block;
}

.container[data-name="crawlers"] .table .table__body .table__cell:nth-of-type(5) .material-icons {
    padding-right : 1rem;
}

.container[data-name="crawlers"] .table__cell--head {
    font-size   : 0.875rem;
    font-weight : 500;
}

.container[data-name="crawlers"] .table__cell .switch {
    font-size : 0.875rem;
}

.container__header--crawler{
    display: none;
}

.org-settings-page__crawler-table .container__header--crawler {
    display: flex;
}
