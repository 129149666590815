.update-access-dialog {
    width: 50rem;
    overflow: unset;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
}

.update-access-dialog__content{
    padding-right: 1rem;
    flex: 1 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.update-access-dialog__buttons{
    flex: 0 0;
}

.update-access-dialog__find-block{
    flex: 0 0;
    height: 4rem;
    margin-bottom: 1rem;
}

.update-access-dialog__permissions-block{
    overflow: auto;
    padding: 1rem 0;
}

.update-access-dialog__row {
    display: flex;
    min-height: 4rem;
    justify-content: space-between;
    align-items: center;
}

.update-access-dialog__name-block {
    min-height: 4rem;
    flex: 3;
    margin-right: 2rem;
}

.update-access-dialog__access {
    flex: 2;
    margin-right: 1rem;
    max-width: 14rem;
}

.update-access-dialog__button {
    width: 4.5rem;
    text-align: center;
    height: 4rem;
}

.update-access-dialog__grant-btn {
    height: 2.25rem;
    width: 4.5rem;
}

.update-access-dialog__subject-name {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.update-access-dialog__delete-btn {
    cursor: pointer;
    color: var(--mdc-theme-text-icon-on-background);
    display: none;
    height: 1.5rem;
    width: 1.5rem;
    margin: 1.25rem 0;
}

.update-access-dialog__permission-row:hover .update-access-dialog__delete-btn {
    display: inline-block;
}

.update-access-dialog__delete-btn:hover {
    color: var(--mdc-theme-text-primary-on-background);
}

.update-access-dialog__icon {
    margin-right: 1rem;
    flex-shrink: 0;
}

.update-access-dialog__team-icon {
    width: 2rem;
    height: 2rem;
    border: 1px solid var(--icon-team);
    border-radius: 50%;
    color: var(--icon-team);
    text-align: center;
}

.update-access-dialog__team-icon span{
    /* We don't use line-height equal to icon height, because icon is not symmetrical
       and looks better if we move it up a little */
    line-height: 1.8rem;
}

.update-access-dialog__user-full-name{
    padding-right: 0.75rem;
}

.update-access-dialog__user-full-name:empty {
    display: none;
}

.update-access-dialog__username {
    font-size: 0.9rem;
}

.update-access-dialog__dropdown-item {
    list-style: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: flex;
}

.update-access-dialog__dropdown-item:hover {
    background: var(--list-background-on-hover-color);
    cursor: pointer;
}

.update-access-dialog__dropdown-username {
    font-weight: bold;
}

.update-access-dialog__item-description {
    font-size: 0.75rem;
    opacity: 0.8;
}

.update-access-dialog__save-button .update-access-dialog__save-button-text--save-and-grant {
    display: none;
}

.update-access-dialog__save-button[grant-and-save] .update-access-dialog__save-button-text--save-and-grant {
    display: inline;
}

.update-access-dialog__save-button[grant-and-save] .update-access-dialog__save-button-text--save {
    display: none;
}