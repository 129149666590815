.post-page {
    width: 100%;
    flex: 1 1 auto;
    padding: 4rem 0;
}

.post-page__container {
    padding: 1rem 2rem;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.post {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
}

.post__header {
    margin: 2rem 0 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.post__title {
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 1rem;
}

.post__data {
    color: var(--theme-text-hint-on-light);
    font-style: italic;
}

.post__buttons-block {
    display: flex;
    gap: 1rem;
}