@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

* {
	font-family: Lato, sans-serif;
	-webkit-font-smoothing: antialiased;
}

*, :after, :before {
	box-sizing : border-box;
}

@font-face{
	font-family : 'Material Icons';
	font-style  : normal;
	font-weight : 400;
	src         : url(../font/font.woff2) format('woff2');
}

:root {
	--theme-element-background     : white;
	--md-theme-background             : rgb(238, 238, 238);
	--md-theme-input-border           : rgb(127, 127, 127);
	--theme-input-border-error     : rgb(229, 57, 53);
	--theme-text-primary-on-light  : rgba(0, 0, 0, 0.87);
	--theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
	--theme-text-hint-on-light     : rgba(0, 0, 0, 0.38);
	--theme-primary               : rgb(6, 95, 212);
	--theme-primary-light         : rgba(6, 95, 212, .1);
	--theme-primary-light-hover   : rgba(6, 95, 212, .2);
	--theme-primary-light-active  : rgba(6, 95, 212, .3);
	--icon-team                   : rgba(6, 95, 212, 0.63);
	--theme-secondary-light       : rgba(1, 135, 134, 0.5);
	--box-shadow                      : 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
	--card-text-color                 : rgba(0, 0, 0, .5);
	--font-size-secondary             : 0.875rem;
	--list-background-on-hover-color  : rgba(0, 0, 0, 0.04);
	--list-background-secondary-color : #f9f9f9;
	--border-color                    : #dee2e6;
	--border-width                    : 0.0625rem;
	--border-radius                   : 0.375rem;
	--plain-border                    : var(--border-width) solid var(--border-color);
	--background-button-disabled      : rgba(0, 0, 0, 0.1);
	--background-button-text-active   : rgba(0, 0, 0, 0.1);
	--background-button-text-hover    : rgba(0, 0, 0, 0.04);
	--box-shadow-hover                : 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
	--page-header-box-shadow          : 0 0 10px rgba(0, 0, 0, 0.5);
	--page-header-title-line-height   : 2.5rem;
	--vis-shadow-color                : rgba(0, 208, 22);
	--vis-shadow-forbidden-color      : rgba(246, 129, 129, 0.7);
	--footer-height: 11rem;
	--table-border: 1px solid #ddd;
}

@-webkit-keyframes indeterminate{
	0%{
		left  : -35%;
		right : 100%;
	}
	60%{
		left  : 100%;
		right : -90%;
	}
	100%{
		left  : 100%;
		right : -90%;
	}
}

@keyframes indeterminate{
	0%{
		left  : -35%;
		right : 100%;
	}
	60%{
		left  : 100%;
		right : -90%;
	}
	100%{
		left  : 100%;
		right : -90%;
	}
}

@-webkit-keyframes indeterminate-short{
	0%{
		left  : -200%;
		right : 100%;
	}
	60%{
		left  : 107%;
		right : -8%;
	}
	100%{
		left  : 107%;
		right : -8%;
	}
}

@keyframes indeterminate-short{
	0%{
		left  : -200%;
		right : 100%;
	}
	60%{
		left  : 107%;
		right : -8%;
	}
	100%{
		left  : 107%;
		right : -8%;
	}
}

html {
	height     : 100%;
	scroll-padding-top: 80px; /* for header when navigate inside of the page */
}

body {
	background : white;
	margin     : 0;
	width      : 100%;
	min-height : 100vh;
	display: flex;
	flex-direction: column;
}

.injection-page-body, .batch-page-body {
	background : var(--md-theme-background);
}

ul {
	list-style : none;
	margin     : 0;
	padding    : 0;
}

text{
	user-select : none;
}

svg{float : left;}

p{
	margin : 0;
	cursor : default;
}

input{
	display       : block;
	width         : 5em;
	border        : none;
	border-bottom : 1px solid var(--md-theme-input-border);
	color         : var(--theme-text-primary-on-light);
	outline       : 0;
	padding       : 16px 0 8px 0;
	cursor        : text;
}

input.error{
	border-bottom : 1px solid var(--theme-input-border-error);
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button{
	-webkit-appearance : none;
	margin             : 0;
}

input[type=number]{-moz-appearance : textfield;}

input[type=checkbox]{
	cursor : pointer;
}
input[type=checkbox][disabled]{
	cursor : default;
}

input::placeholder{opacity : 0.5;}

.search-input{
	position : relative;
	padding  : 1rem 0 0.5rem 0;
	height   : 2rem;
	width    : 18.5rem;
	display  : inline-block;
}

.search-input.sampleList{
	left : 50%;
}

.search-input input{
	font-size        : 1rem;
	line-height      : 1rem;
	margin           : 0;
	padding          : 0.5rem 0;
	width            : 100%;
	text-align       : left;
	background-color : transparent;
}

.search-input input:focus{
	outline : none;
}

.search-input label{
	position           : absolute;
	left               : 0;
	top                : 1.625rem;
	width              : 100%;
	color              : var(--theme-text-hint-on-light);
	font-size          : 1rem;
	line-height        : 1rem;
	font-weight        : normal;
	pointer-events     : none;
	transition         : 0.2s ease all;
	-moz-transition    : 0.2s ease all;
	-webkit-transition : 0.2s ease all;
}

.search-input input:focus ~ label{
	top       : 0;
	font-size : 0.75rem;
	color     : var(--theme-text-primary-on-light);
}

.search-input label.not-empty{
	top       : 0;
	font-size : 0.75rem;
}

.search-input .bar{
	display            : block;
	width              : 0;
	margin-left        : 50%;
	height             : 1px;
	background         : var(--theme-text-primary-on-light);
	opacity            : 0;
	transition         : 0.2s ease all;
	-moz-transition    : 0.2s ease all;
	-webkit-transition : 0.2s ease all;
}

.search-input input:focus ~ .bar{
	opacity     : 1;
	width       : 100%;
	margin-left : 0;
}

.search-input #total{
	float       : left;
	margin-top  : 8px;
	font-size   : 14px;
}

.material-icons{
	font-family                   : 'Material Icons', sans-serif;
	font-weight                   : normal;
	font-style                    : normal;
	font-size                     : 24px;
	line-height                   : 1;
	letter-spacing                : normal;
	text-transform                : none;
	display                       : inline-block;
	white-space                   : nowrap;
	word-wrap                     : normal;
	direction                     : ltr;
	-webkit-font-feature-settings : 'liga';
	-webkit-font-smoothing        : antialiased;
	text-rendering                : optimizeLegibility;
	-moz-osx-font-smoothing       : grayscale;
	font-feature-settings         : 'liga';
}

.page-title{text-align : center;}

.mdc-button{text-transform : none !important;}

.clickable {
	cursor : pointer;
}

.material-icons--red {
    color : var(--mdc-theme-error);
}

dialog {
	border: none;
	border-radius : var(--border-radius);
	box-shadow: var(--box-shadow);
	outline: none;
}

.resource-not-found-message-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	padding-top: 4rem;
	gap: 1rem;
	width: 100%;
}

.account-icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	margin: 0;
	font-weight: bold;
	font-size: 0.85rem;
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
	color: white;
	opacity: 0.6;
	flex-shrink: 0;
}

.user-icon__initials {
	text-decoration: none;
}