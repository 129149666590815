.accordion__item {
    background-color : var(--mdc-theme-background);
    border           : var(--plain-border);
}

.accordion__item:first-of-type {
    border-top-left-radius  : var(--border-radius);
    border-top-right-radius : var(--border-radius);
}

.accordion__item:not(:first-of-type) {
    border-top : 0;
}

.accordion__item:last-of-type {
    border-bottom-right-radius : var(--border-radius);
    border-bottom-left-radius  : var(--border-radius);
}

.accordion__item-header {
    display         : flex;
    padding         : 0.75rem 1rem;
    justify-content : space-between;
    align-items     : center;
}

.accordion__item:not([collapsed]) > .accordion__item-header {
    box-shadow : inset 0 calc(-1 * var(--border-width)) 0 var(--border-color);
}

.accordion__item:not([collapsed]) .accordion__item-header {
    box-shadow : inset 0 calc(-1px) 0 var(--border-color);
}

.accordion__collapse-icon {
    color     : var(--theme-text-secondary-on-light);
    transform : rotate(-180deg);
    font-size : 1.25rem;
}

.accordion__item[collapsed] .accordion__collapse-icon {
    transform : rotate(0deg);
}

.accordion__item[collapsed] > .accordion__inner {
    display : none;
}