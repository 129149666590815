.spectrum-graph {
    width  : 100%;
    height : 100%;
}

.spectrum-graph svg {
    position : absolute;
    overflow : visible;
}

.spectrum-graph__axes-layer {
    width  : 100%;
    height : 100%;
}

/**
sg - spectrum-graph
sg__b - spectrum-graph__bar
sg__lol-c - spectrum-graph__lollipop-circle

Because there are too many of them on the charts, we abbreviate so that HTML isn't too large
 */
.sg__b {
    fill : #757575;
}

.sg__lol-c {
    /*Half of bar width*/
    transform : translate(0.5px, 0);
}

.sg__l {
    text-anchor : start;
    font-size   : 11px;
    fill        : #0000cd;
    transform   : matrix(1, 0, 0, -1, 0, 0) rotate(-90deg);
}