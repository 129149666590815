.action-icons {
    display     : flex;
    align-items : center;
    gap         : 0.25rem;
    padding     : 0.25rem 0.5rem;
}

.action-icons__item {
    font-size   : 1rem;
    line-height : 1.25rem;
    padding     : 0.25rem;
    color       : var(--mdc-theme-text-icon-on-background);
}
.action-icons__item--after-input {
    font-size   : 1.3rem;
    padding: 0;
}

.action-icons__item:not(.action-icons__item--disabled):hover {
    cursor : pointer;
    color  : var(--mdc-theme-text-primary-on-background);
}

.action-icons__item--disabled:hover {
    cursor : not-allowed;
}
