.table {
    width           : 100%;
    height          : 100%;
    border-collapse : collapse;
    border-spacing  : 0;
    font-weight     : 300;
}

.table__cell--head {
    font-size   : 0.875rem;
    font-weight : 500;
    text-align  : start;
}

.table .table__row {
    height        : 48px;
    border-bottom : 1px #eee solid;
}

.table tbody .table__row:hover {
    background-color : var(--list-background-on-hover-color);
}

.table__cell--visible-on-hover > * {
    visibility : hidden;
}

.table__row:hover .table__cell--visible-on-hover > * {
    visibility : visible;
}

.table__row--empty-table-message {
    font-style     : italic;
    text-align     : center;
    pointer-events : none;
    font-weight    : 400;
}

.table__cell--one-line-value {
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}