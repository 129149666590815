.table-page-container {
    width: 100%;
    flex: 1 1 auto;
    box-sizing: border-box;
    background: white;
    padding: 4rem 0;
}
.landing-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: 4em;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.landing-tabs__list {
    padding-left : 2.5rem;
}
.landing-tabs__item {
    list-style-type: none;
    display: inline;
    border: 3px solid rgba(0, 0, 0, 0);
    padding-bottom: 10px;
}
.landing-tabs__item--current {
    border-bottom: 3px solid var(--theme-primary);
}
.landing-tabs__link {
    color: black;
    text-decoration: none;
    font-weight: 400;
    padding: 10px 15px;
}
.landing-tabs__link:hover, .landing-tabs__link--current {
    color: var(--theme-primary);
}

.landing-search {
    height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.landing-search__icon {
    color: grey;
    padding: 0 15px 0 20px;
}
.landing-search__input {
    border: none;
    height: 90%;
    padding: 0;
    flex: 1 1 0;
}
.n-of-found {
    font-style: italic;
    font-size: .8em;
    margin-right: 20px;
}
.n-of-found > span {
    margin: 0 .2em;
}
.landing-search__button {
    margin-right: 20px;
}