.base-dialog {
    min-width: 32rem;
    max-width: 85vw;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    --dialog__header__height: 3em;
    --dialog__header__top-padding: 24px;
    --dialog__header__bottom-padding: 10px;
    --dialog__footer__height: 3em;
    --dialog__right-padding: 24px;

    /*need to keep right padding 0 so that the content could extend fully to the right and have its own padding.
     This way its scroll bar will touch popup right border instead of being padded.*/
    padding: 24px 0 var(--dialog__header__bottom-padding) 24px;
    background: white;
    outline: none;
}

.dialog__header {
    font-size: 1.25em;
    padding-right: var(--dialog__right-padding);
}

.dialog__main-content {
    /*
      We want the content of the popup to be scrollable while the header and the footer (buttons) stay on the screen,
      so the max height of the content is the screen height minus header & footer.

      The calculations are a bit off since the scroll appears sooner when you start shrinking the window,
      was lazy to figure out the mistake. But that's fine - we'd add extra space anyway, don't want to touch
      browser boundaries.

      There will be dialogs w/o header or footer, but those are usually small ones. Their max-height will have
      extra pixels, well.. because they have no actual header or footer. But for small popups this isn't critical
      because no one will shrink the browser that much.
    */
    max-height: calc(100vh - var(--dialog__header__height)
    - var(--dialog__header__top-padding) - var(--dialog__header__bottom-padding)
    - var(--dialog__footer__height));
    overflow: auto;
    margin: 1rem 0;
    padding-right: var(--dialog__right-padding);
}

.dialog__main-content--stack-trace-only {
    font-family: monospace;
    font-size: .75rem;
    line-height: 1.3;
    color: var(--theme-text-primary-on-light);
    white-space: pre;
}

.dialog__footer {
    height: var(--dialog__footer__height);
    padding-right: var(--dialog__right-padding);
}

.dialog__footer--main-buttons-only {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dialog__footer--main-buttons-only > button {
    margin-left: 0.5rem;
}

.bad-request-dialog__list-item {
    padding: 0.5rem 0;
}