edit-text {
  display: inline-block;
  max-width: 100%;
}

.edit-text__popup {
  max-width: 100%;
  min-width: 8rem;
  width: auto;
  box-shadow: var(--box-shadow);
  position: absolute;
  border: none;
  margin: -0.7rem 0 0 -0.75rem;
  padding: 0;
}

.edit-text__popup text-input {
  width: calc(100% - 1rem);
  padding-top: 0;
  margin: .5rem .5rem 1rem;
}

.edit-text__popup .text-input__error {
  height: unset;
}

.edit-text__popup::backdrop {
  background: transparent;
}

.edit-text__popup .text-input--with-right-icon .text-input__input {
  padding-right: 0;
}

edit-text .edit-text__text {
  text-decoration: underline;
  cursor: pointer;
  font-weight: normal;
}

edit-text[disabled] .edit-text__text {
  text-decoration: none;
  cursor: default;
  pointer-events: none;
}

edit-text[incorrect] .edit-text__text{
  color: var(--theme-input-border-error);
}

.edit-text__text[empty-value] {
  font-style: italic;
}
