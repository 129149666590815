dialog.mass-spec-overview {
    width: 36rem;
}

dialog.mass-spec-overview .dialog-content {
    padding: .25rem .75rem .25rem .75rem;
    border-width: 1.25rem 0;
    border-color: var(--theme-element-background);
    border-style: solid;
    overflow: auto;
    max-height: 80vh;
}

dialog.mass-spec-overview .mass-spec h3 {
    margin: 0;
}

dialog.mass-spec-overview .mass-spec:not(:last-child) {
    margin-bottom: 1rem;
}

dialog.mass-spec-overview .dialog-buttons {
    height: 2.25rem;
}

dialog.mass-spec-overview .mass-spec:not(:last-child) {
    margin-bottom: 1rem;
}

dialog.mass-spec-overview .mass-spec h3 {
    margin: 0;
}

.ions-with-chart {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    gap: 3rem;
}


/*td.mass-spec-row__title, .mass-spec__mass-resolution>span {*/
/*    font-style: italic;*/
/*    color: var(--theme-text-secondary-on-light);*/
/*}*/

td.mass-spec-row__value:not([disabled]) {
    color: var(--theme-text-primary-on-light);
    text-decoration: underline;
    cursor: pointer;
}

.mass-spec-ion__line--quantifier {
    stroke: blue;
}

.mass-spec-ion__rect--quantifier {
    fill: lightblue;
}

.mass-spec-ion__line--qualifier {
    stroke: green;
}

.mass-spec-ion__rect--qualifier {
    fill: lightgreen;
}

.mass-spec__header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}
