.named-list-item {
    padding: .3em 0;
}
.named-list-item.fade-out {
    opacity : 0.4;
}
.named-list-item__title {
    font-size: .75em;
    color: var(--theme-text-hint-on-light);
}
.named-list-item:hover .named-list-item__title {
    color: var(--theme-primary);
}