dialog.fragment-config .dialog-content {
    padding: .25rem .75rem .25rem .75rem;
    border-width: 1.25rem 0;
    border-color: var(--theme-element-background);
    border-style: solid;
    overflow: auto;
    max-height: 80vh;
}

dialog.fragment-config .dialog-buttons {
    height: 2.25rem;
}

dialog.fragment-config header {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2.25rem;
}

dialog.fragment-config header h3 {
    margin: 0;
}

dialog.fragment-config .table__body--empty::after {
    content: "empty";
    color: darkgray;
}

dialog.fragment-config input[type="checkbox"] {
    width: 1rem
}

dialog.fragment-config th {
    text-align: center;
    vertical-align: middle;
    min-width: 7rem;
}

dialog.fragment-config th:first-child, dialog.fragment-config th:last-child {
    min-width: 2rem;
}

dialog.fragment-config td {
    text-align: center;
    vertical-align: middle;
}

.table__row--fragment-config .table__cell.clickable {
    text-decoration: underline;
    text-underline-offset: 4px;
}

dialog.fragment-config section[data-fragment-type] header button[data-name="add-fragment"] {
    display: none;
}

dialog.fragment-config section[data-fragment-type]:hover header button[data-name="add-fragment"] {
    display: block;
}

dialog.fragment-config section[data-fragment-type]:not(:last-of-type){
    margin-bottom: 1rem;
}