.page-header--import-status-page .page-header__section--align-start {
    /*The table body has a width of 1000px.*/
    /*Since the table is centered, we can divide the remaining space by 2 to*/
    /*align the end of the first header section with the start of the table.*/
    max-width: calc(calc(100% - 1000px) / 2);
    min-width: 10rem;
}

.page-header--import-status-page .page-header__breadcrumbs-section {
    /*We don't want to show the breadcrumbs on this page*/
    display: none;
}
.page-header--import-status-page .page-header__section--align-mid {
    max-width: 1000px;
}