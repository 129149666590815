.chromatogram-graph__scan-selector {
    overflow : visible;
}

.shadow-scan-selector-stripe {
    fill             : none;
    pointer-events   : none;
    stroke           : #9e9e9e;
    stroke-dasharray : 4, 4;
}

.shadow-scan-selector-rect {
    opacity : 0.9;
    fill    : #616161;
}

.shadow-scan-selector-text {
    font-size   : 75%;
    fill        : white;
    user-select : text;
}

.scan-selector-line-red {
    fill           : none;
    stroke         : #F44336;
    stroke-width   : 1px;
    pointer-events : none;
}
