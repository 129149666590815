.container[data-name="teams-list"] {
    min-width: 21rem;
    border: none;
    border-radius: 0;
}

.container[data-name="teams-list"] .container__body {
    padding: 1rem 0;
}

.container[data-name="teams-list"] .table__cell:nth-of-type(1) {
    padding-left: 1.5rem;
}

.table__cell--team-name {
    padding: 0.5rem 1rem 0.5rem 1.5rem;
}

.table__row-team {
    cursor: pointer;
}

.table__team-description {
    padding: 0.5rem 0;
    font-size: 0.9rem;
}

.table__team-name-link, .table__team-name-link:hover  {
    color: var(--theme-text-primary-on-light);
    text-decoration: none;
    font-weight: bold;
}