.echoMs-split-popup {
    min-width: 34rem;
    width: 80%;
}

.echoMs-split-popup #chromatogram-graph {
    height: 200px;
}

.echoMs-split-popup #header-with-zoom-icon {
    display: flex;
    justify-content: flex-end;
}

.echoMs-split-popup #chromatogram-header-zoom-out-icon {
    font-size: var(--chromatogram-card__header-line-height);
    color: var(--mdc-theme-text-icon-on-light);
    transition: color 250ms;
}

.echoMs-split-popup #chromatogram-header-zoom-out-icon:hover {
    cursor: pointer;
    color: var(--theme-text-primary-on-light);
    scale: 1.1;
}