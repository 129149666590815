#zoom-range-picker ul {
    list-style-type : none;
    margin          : 0;
    padding         : 0;
    max-height      : 300px;
}

#zoom-range-picker li {
    cursor : pointer;
}
