.container[data-name="processing-methods"] {
    min-width     : 21rem;
    border        : none;
    border-radius : 0;
}

.container[data-name="processing-methods"] .container__body {
    padding : 1rem 0;
}

.container[data-name="processing-methods"] .table {
    table-layout                 : fixed;
    --table-col-1-width          : 4.5rem;
    --table-col-4-width          : 5.75rem;
    --middle-columns-total-width : calc(100vw - var(--table-col-1-width) - var(--table-col-4-width) - 9.375rem/*sidenav*/ - 26px /*scrollbar*/);
    --table-col-2-width          : calc(var(--middle-columns-total-width) * 0.65);
    --table-col-3-width          : calc(var(--middle-columns-total-width) * 0.35);
}

.container[data-name="processing-methods"] .table__cell {
    padding-left  : 0.5rem;
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
}

.container[data-name="processing-methods"] .table .table__cell:nth-of-type(1) {
    padding-left : 1.5rem;
    width        : var(--table-col-1-width);
}

.container[data-name="processing-methods"] .table .table__cell:nth-of-type(2) {
    width : var(--table-col-2-width);
}

.container[data-name="processing-methods"] .table .table__cell:nth-of-type(3) {
    width : var(--table-col-3-width);
}

.container[data-name="processing-methods"] .table .table__cell:nth-of-type(4) {
    width         : var(--table-col-4-width);
    padding-right : 1rem;
}

.container[data-name="processing-methods"] .table__cell--head {
    font-size   : 0.875rem;
    font-weight : 500;
}

.container[data-name="processing-methods"] .table__cell .switch {
    font-size : 0.875rem;
}