.list {
    overflow : hidden;
}

.list__item {
    display     : flex;
    align-items : center;
}

.list__item:hover {
    background-color : var(--list-background-on-hover-color) !important;
}

.list__item--border-bottom:not(:last-of-type) {
    box-shadow : inset 0 calc(-1px) 0 var(--border-color);
}

.list__item-cell:first-of-type {
    padding-left : 1.5rem;
    flex         : 1 1 auto;
}

.list__item-cell--text {
    padding       : 0.5rem;
    overflow      : hidden;
    white-space   : nowrap;
    text-overflow : ellipsis;
    color         : var(--theme-text-primary-on-light);
    font-size     : var(--font-size-secondary);
}

.list__item-cell--text-n-lines {
    display        : flex;
    flex-direction : column;
    padding        : 0.5rem;
    color          : var(--theme-text-primary-on-light);
    font-size      : var(--font-size-secondary);
    line-height    : 1.25rem;
}

.list__item-cell--visible-on-hover {
    visibility : hidden;
}

.list__item:hover > .list__item-cell--visible-on-hover {
    visibility : visible;
}
