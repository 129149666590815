:root {
    --peak-spectrum-card__header-height : 26px;
    --peak-spectrum-card__body-height   : calc(100% - var(--peak-spectrum-card__header-height));
}

.peak-spectrum-card {
    width      : 219px;
    height     : 208px;
    border     : 1px solid gray;
    margin     : 8px;
    display    : inline-block;
    background : var(--theme-element-background);
    position   : relative;
}

.peak-spectrum-card__header {
    font-size     : 14px;
    padding       : 8px 4px 0 12px;
    height        : var(--peak-spectrum-card__header-height);
    box-sizing    : border-box;
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}

.peak-spectrum-card__body {
    height   : var(--peak-spectrum-card__body-height);
    position : relative;
}
