.footer {
    font-weight: normal;
    font-size: 0.8rem;
    border-top: 1px solid var(--border-color);
    background: transparent;
    height: var(--footer-height);
    flex: 0;
}

.injection-page-body .footer{
    border-top: none;
}

.footer-row {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem 2rem;
    width: 100%;
    max-width: 75rem;
    flex-wrap: wrap;
    margin: 0 auto;
}

.footer-section {
    padding-top: 1rem;
    min-width: 190px;
}

.footer__list-item {
    margin: 0.3rem 0;
}
.footer__link {
    color: black;
    text-decoration: none;
}
.footer__link:hover {
    text-decoration: underline;
}

.footer__address p {
    margin: 0.3rem 0;
    font-style: normal;
}

.footer-section__title {
    font-weight: 400;
    padding-bottom: 0.5rem;
}
