.substance-dialog {
    width: 576px;
    outline: none;
}

.substance-dialog .dialog-content {
    display: flex;
    padding: 1rem 1rem 1.5rem 1rem;
    justify-content: space-between;
}

.substance-dialog .dialog-content-col {
    display: flex;
    height: 222px;
    flex-direction: column;
    justify-content: space-between;
}

.substance-dialog .col-1 {
    width: 196px;
}

.substance-dialog #alias, .substance-dialog #mfOrEmw {
    display: block;
    height: 4rem;
}

#substance-dialog-color:not([disabled]) {
    display: block;
    position: relative;
    cursor: pointer;
    margin-top: 36px;
}

#substance-dialog-color span {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.64);
}

#substance-dialog-color .color-preview-rect {
    width: 36px;
    height: 18px;
    display: inline-block;
    margin-left: 8px;
    box-shadow: 0 0 2px -1px rgb(0 0 0 / 14%), 0 2px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 14%);
    vertical-align: sub;
}

#substance-dialog-color color-palette {
    position: absolute;
    top: -122px;
    right: -140px;
    z-index: 1;
}

.substance-dialog .col-2 {
    width: 296px;
}

.drop-structure__message {
    display: flex;
    flex-direction: column;
    align-items: center;
}
