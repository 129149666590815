
.snackbar-container {
    --snackbar-background-color: rgb(51, 51, 51);
    --snackbar-background-color-hover: rgb(84, 84, 84);
    --snackbar-text-label-color: rgba(255, 255, 255, 0.87);
    --snackbar-text-action-color: rgb(187, 134, 252);
    --snackbar-text-action-disabled-color: rgb(140, 140, 140);
    z-index : 8;
    margin : 0.5rem;
    position : fixed;
    right : 0;
    bottom : 0;
    left : 0;
    display : flex;
    align-items : start;
    justify-content : center;
    box-sizing : border-box;
    flex-direction: column;
    pointer-events: none;
}

.snackbar {
    pointer-events: all;
    margin: 0.5rem;
    background-color : var(--snackbar-background-color);
    min-width : 344px;
    box-shadow : var(--box-shadow);
    max-width : 672px;
    border-radius : 4px;
    display : flex;
    align-items : center;
    justify-content : flex-start;
}

@media (max-width : 480px), (max-width : 344px){
    .snackbar__surface{
        min-width : 100%;
    }
}

.snackbar__label{
    color : var(--snackbar-text-label-color);
}

.snackbar__label{
    font-size : 0.875rem;
    line-height : 1.25rem;
    font-weight : 400;
    letter-spacing : 0.012rem;
    flex-grow : 1;
    box-sizing : border-box;
    margin : 0;
    padding : 1rem;
}


.snackbar__buttons {
    margin-left : 0.5rem;
    margin-right : 0.5rem;
    display : flex;
    flex-shrink : 0;
    align-items : center;
    box-sizing : border-box;
}

.snackbar__button-ok {
    background: var(--snackbar-background-color);
    color : var(--snackbar-text-action-color);
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
}

.snackbar__button-ok:disabled, .snackbar__button-close:disabled {
    border: none;
    color: var(--snackbar-text-action-disabled-color);
    pointer-events: none;
}

.snackbar__button-ok:hover, .snackbar__button-close:hover{
    background: var(--snackbar-background-color-hover);
}

.snackbar__button-close {
    background: var(--snackbar-background-color);
    color : var(--snackbar-text-label-color);
    border: none;
    cursor: pointer;
    font-size: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    padding: 0;
}
