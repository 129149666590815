dialog.ion-config .dialog-content {
    padding: .25rem .75rem .25rem .75rem;
    border-width: 1.25rem 0;
    border-color: var(--theme-element-background);
    border-style: solid;
    overflow: auto;
    max-height: 80vh;
}

dialog.ion-config .dialog-buttons {
    height: 2.25rem;
}

.ions-with-chart {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/*td.mass-spec-row__title {*/
/*    font-style: italic;*/
/*    color: var(--theme-text-secondary-on-light);*/
/*}*/

input.table-cell-input {
    padding: 0;
    border: none;
}

.mass-spec-ion__line {
    stroke: blue;
}

dialog.ion-config h3:first-of-type {
    margin-top: 0;
}

.mass-spec-ion__rect {
    fill: lightblue;
}

tbody tr td[data-name="int"] span {
    opacity: .2
}

tbody.qualifier-group tr td[data-name="int"] span {
    opacity: 1;
}

.section__table {
    max-height: calc(80vh - 20rem);
    overflow: auto;
}

dialog.ion-config .table .table__row {
    height: 2.25rem;
}

dialog.ion-config th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--theme-element-background);
}

dialog.ion-config th:last-child, dialog.ion-config td:last-child {
    padding-left: 1rem;
    padding-right: 1rem;
}

.table__row--disabled span, tbody.qualifier-group .table__row--disabled td[data-name="int"] span {
    opacity: 0.2;
}

.table__cell--italic {
    font-style: italic;
}

dialog.ion-config .table__cell.intensity-resolution,
dialog.ion-config .table__cell.mass-to-charge {
    width: 7rem;
}

dialog.ion-config .table__cell.intensity-resolution {
    text-align: center;
}

dialog.ion-config .table__cell.ion-name {
    min-width: 10rem;
}

