.peak__area {
    stroke         : #000066;
    pointer-events : none;
}

.peak-creator__overlay {
    cursor         : crosshair;
    fill           : none;
    stroke         : none;
    pointer-events : all;
}

.peak-creator__selection {
    cursor          : crosshair;
    fill            : rgb(119, 119, 119);
    fill-opacity    : 0.3;
    shape-rendering : crispedges;
    stroke          : none;
}

.peak-editor__overlay {
    cursor          : move;
    fill            : rgb(119, 119, 119);
    fill-opacity    : 0.3;
    shape-rendering : crispedges;
    stroke          : none;
}

.peak-editor__border {
    cursor       : ew-resize;
    fill-opacity : 0;
}

.peak-editor__anchor {
    visibility             : visible;
    pointer-events         : all;
    font-family            : Lato, sans-serif;
    -webkit-font-smoothing : antialiased;
    fill                   : #2b2b2b;
}