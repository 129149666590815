.bulk-operation-warning-dialog {
    width: 70vw;
    min-width: 30rem;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.bulk-operation-warning-dialog .dialog-buttons-row {
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
}

.bulk-operation-warning-dialog .dialog-row{
    height: unset;
}

.bulk-operation-warning-dialog .dialog-row:nth-child(2){
    max-height: 40vh;
    overflow-y: auto;
    margin: 1rem 0;
}

.bulk-operation-warning-dialog li span:nth-child(2) {
    padding-left: 1rem;
    white-space: nowrap;
}

.bulk-operation-warning-dialog li {
    height: 1.5rem;
    display: flex;
}

