.user-menu {
    padding: 0;
    margin: 0;
    top: 4rem;
    left: auto;
    border-radius: 0.375rem;
    width: 18rem;
    overflow: hidden;
}

.user-menu__menu-wrapper {
    padding: 0.75rem;
    display: grid;
    margin: 0;
    background-color: var(--theme-element-background);
    grid-template-columns: 1fr;
    top: 1rem;
}

.user-menu__header {
    display: flex;
    align-items: center;
    height: 2.625rem;
    overflow: hidden;
}

.user-menu__name {
    max-height: 2.2rem;
    line-height: 1.1;
    overflow: hidden;
    color: var(--theme-text-primary-on-light);
}

.user-menu__initials-icon {
    flex: 0 0 2rem;
    margin-right: .5rem;
}

.user-menu__menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.25rem;
    min-height: 2.625rem;
    cursor: pointer;
    color: var(--theme-text-primary-on-light);
}

.user-menu__menu-item:hover:not([disabled]) {
    background-color: var(--list-background-on-hover-color);
}

.user-menu__menu-item[disabled]{
    cursor: default;
    color: var(--theme-text-hint-on-light);
}

.user-menu::backdrop{
    background-color: rgba(0, 0, 0, 0.05);
}

.user-menu__separator {
    display: none;
}

.user-menu__separator:has(+ li) {
    display: block;
    height: 1px;
    width: 100%;
    background: var(--border-color);
    margin: 0.75rem 0;
}

.user-menu__arrow-icon {
    margin-left: 1rem;
}

.user-menu__side-menu {
    padding: 0.75rem;
    margin: 0;
    top: 9rem;
    left: auto;
    right: 18rem;
    max-height: 24rem;
    min-width: 12rem;
}

.user-menu__side-menu::backdrop{
    background-color: transparent;
}

.user-menu__menu-item--link {
    padding: 0;
}

.user-menu__link, .user-menu__link:visited {
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    color: var(--theme-text-primary-on-light);
    line-height: 1.625rem;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.user-menu__section-title {
    list-style: none;
    padding: 0.5rem;
    font-size: 1rem;
    color: var(--theme-text-hint-on-light);
    user-select: none;
}