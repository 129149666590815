.spinner {
    width            : 100%;
    height           : 100%;
    position         : absolute;
    background-color : rgba(255, 255, 255, 0.6);
    top              : 0;
    left             : 0;
    display          : flex;
    align-items      : center;
    justify-content  : center;
}

.spinner svg {
    -webkit-animation : rotation 1.35s linear infinite;
    animation         : rotation 1.35s linear infinite;
    position          : relative;
    stroke            : #9c27b0;
    display           : flex;
}


.spinner svg circle {
    stroke-dasharray         : 180;
    stroke-dashoffset        : 0;
    -webkit-transform-origin : center;
    transform-origin         : center;
    -webkit-animation        : turn 1.35s ease-in-out infinite;
    animation                : turn 1.35s ease-in-out infinite;
}

@keyframes rotation {
    0% {
        transform : rotate(0deg);
    }
    100% {
        transform : rotate(270deg);
    }
}

@keyframes turn {
    0% {
        stroke-dashoffset : 180;
    }
    50% {
        stroke-dashoffset : 45;
        transform         : rotate(135deg);
    }
    100% {
        stroke-dashoffset : 180;
        transform         : rotate(450deg);
    }
}
